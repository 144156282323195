import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/libs/util';
import MainViewLink from 'src/apps/common/components/MainViewLink';
import routes from '../../../common/routes';

type SvButtonProps = {
  svMetaId: string;
  svType: number;
};

const SvButton = function SvButton(props: SvButtonProps) {
  let svBtnText = 'ハイライトを見る';

  if (props.svType == 2) {
    svBtnText = 'フルで見る';
  }

  return (
    <MainViewLink to={routes.content} params={{ id: props.svMetaId }} className="action-btn">
      <i className="fa fa-arrow-switch"></i>
      <span>{svBtnText}</span>
    </MainViewLink>
  );
};

export default React.memo<SvButtonProps>(SvButton);
