import React from 'react';
import _ from 'src/libs/util';
import sepDigit from '../../../../../common/sepDigit';

const Period = props => {
  if (_.isEmpty(props.entitlements)) {
    return null;
  }

  const nodeData = props.entitlements.map((entitlement, i) => {
    const data = {
      from: entitlement.startAt,
      to: entitlement.endAt,
    };
    if (props.entitlements.length === 1) return data;

    const index = _.findIndex(_.get(props, 'product.rights', []), right => {
      // @ts-ignore TS2339
      return right.id == entitlement.rightId;
    });
    if (index !== -1) {
      // @ts-ignore TS2339
      data.name = props.product.rights[index].name;
    }
    return data;
  });

  const nodes = nodeData.map((node, i) => {
    return (
      <div key={`entitlements-${i}`}>
        {node.name ? <span className="right-name">{node.name}</span> : null}
        <span className="right-period">
          {node.from} 〜 {node.to}
        </span>
      </div>
    );
  });

  return (
    <div className="recipe-table-row clearfix">
      <div className="title">視聴期限</div>
      <div className="item">{nodes}</div>
    </div>
  );
};

//
// props
// - product
// - productOrder
// - entitlements
//
export default props => {
  return (
    <div className="recipe-table">
      {!props.product ? null : (
        <div className="recipe-table-row clearfix">
          <div className="title">ご購入コンテンツ</div>
          <div className="item">{props.product.name}</div>
        </div>
      )}
      {!_.get(props.productOrder, 'order') ? null : (
        <div className="recipe-table-row clearfix">
          <div className="title">ご請求金額</div>
          <div className="item">{sepDigit(_.get(props.productOrder, 'order.paymentAmount'))}円（税込）</div>
        </div>
      )}
      <Period {...props} />
    </div>
  );
};
