import _ from 'src/libs/util';
import url from 'url';

import DeleteApp from './DeleteApp';
import { CLICK_AREA, CONTENT_EVENTS } from './GtmApp';
import { PAGING_INITIAL_KEY } from 'src/constants';

class InterestApp extends DeleteApp {
  constructor(service, gtmApp?, model?) {
    super();
    service = Object.assign({}, service);
    service.pathname = _.join(_.concat(service.path, 'users/favorites/bulk_add'), '/');
    // @ts-ignore TS2339
    this._url = url.format(service);
    service.pathname = _.join(_.concat(service.path, 'profiles/update'), '/');
    // @ts-ignore TS2339
    this._updateUrl = url.format(service);
    // @ts-ignore
    this.gtmApp = gtmApp;
    // @ts-ignore
    this.model = model.pathEvaluator;
  }

  async send(authContext, userId) {
    const items = this.getCheckedItems();

    const favorites = JSON.stringify(
      _.map(items, item => {
        return { model_type: 'meta', model_id: item.id };
      }),
    );

    const params = {
      user_id: userId || authContext.id,
      favorites: favorites,
    };

    const convertGtmData = item => {
      const isMediaMeta = _.get(item, 'type') === 'media';
      const hasRelationProgram = _.get(item, 'seriesMeta') && _.get(item, 'seasonMeta');
      const relationProgram = hasRelationProgram
        ? {
            refId: _.get(item, 'seriesMeta.refId'),
            name: _.get(item, 'seriesMeta.name'),
          }
        : null;
      const program = isMediaMeta
        ? {
            refId: _.get(item, 'seasonMeta.refId') || _.get(item, 'seriesMeta.refId'),
            name: _.get(item, 'seasonMeta.name') || _.get(item, 'seriesMeta.name'),
          }
        : {
            refId: _.get(item, 'refId'),
            name: _.get(item, 'name'),
          };
      const content = isMediaMeta
        ? {
            refId: _.get(item, 'refId'),
            name: _.get(item, 'name') || _.get(item, 'shortName'),
            rental: _.get(item, 'rental'),
            subscription: _.get(item, 'subscription'),
          }
        : {
            rental: _.get(item, 'rental'),
            subscription: _.get(item, 'subscription'),
          };
      const attributes = _.get(item, 'attributes');
      const genres = _.get(item, 'genres');
      const middleGenres = _.get(item, 'middleGenres');
      const schemaId = _.get(item, 'schemaId');
      return { content, program, relationProgram, attributes, genres, middleGenres, schemaId };
    };

    const quantity = (await this.getTotalCount()) + 1;
    items.forEach((item, index) => {
      const { content, program, relationProgram, attributes, genres, middleGenres, schemaId } = convertGtmData(item);
      // @ts-ignore
      this.gtmApp.pushDataLayerOnContentPageClick(
        CONTENT_EVENTS.ADD_TO_WISHLIST,
        {
          content,
          program,
          relationProgram,
          attributes,
          genres,
          middleGenres,
          schemaId,
          quantity: quantity + index,
        },
        { clickArea: CLICK_AREA.ADD_TO_WISHLIST.INITIAL_FAVORITE },
      );
    });
    return super.send(params, authContext);
  }

  _checkResponse(response) {
    return true;
    if (response.result) return true;
    const code = _.get(response, 'error.code');
    // 既に削除済み
    if (code == 8004) {
      return true;
    }
    return false;
  }

  registered(authContext, userId) {
    const params = {
      profile_id: userId,
      values: JSON.stringify({ interest_list_selected_flag: true }),
    };

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        let response = xhr.response;
        if (typeof response === 'string') {
          response = JSON.parse(response);
        }
        // console.log(response);
      }
    };

    const query = Object.keys(params)
      .map(function(key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&');

    // @ts-ignore TS2339
    xhr.open('POST', this._updateUrl, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    if (authContext) {
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
      xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
    }
    xhr.send(query);
  }

  //GTM連携用。非公開などは考慮できないのでAPIで取れた値を出す。
  async getTotalCount() {
    const lengthPath = ['mylist', 'cd', PAGING_INITIAL_KEY, 1, 'length'];
    const paths = [lengthPath];
    try {
      // @ts-ignore TS2339
      const res = await this.model.fetch(paths);
      const totalCount = _.get(res.json, lengthPath);
      return totalCount;
    } catch (err) {
      // gtm連携できないだけなので握りつぶす
      // console.log(err);
    }
  }
}

export default InterestApp;
