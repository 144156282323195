import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import BackgroundImage from '../../../common/components/BackgroundImage';
import routes from '../../../common/routes';
import MainViewLink from '../../../common/components/MainViewLink';
// @ts-ignore TS1192
import reactNl2br from '../../../../common/reactNl2br';
import datetime from 'src/libs/datetime';

const FORMAT = 'YYYY/MM/DD HH:mm:ss';
const STATUS_LABELS = {
  1: '中止',
  2: '中断',
  3: 'ライブ前',
  4: 'ライブ中',
  5: '見逃し視聴',
  6: '未定',
  7: '終了',
};

type SoccerCardProps = {
  // フル・ハイライトボタンの表示
  showButton?: boolean;
  [key: string]: any;
};

const SoccerCard = (props: SoccerCardProps, context) => {
  const itemData = props.itemData;
  const headerRef = useRef<HTMLDivElement>(null);
  const getOnClick = (meta = {}) => {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      // @ts-ignore TS2339
      if (!meta || !meta.ref_id) return;
      if (props.onClick) props.onClick(meta);
      // @ts-ignore TS2554
      context.history.push(routes.content.makePath({ id: meta.ref_id }));
    };
  };
  const soccerCardInnerHeight = props.height ? props.height - (headerRef.current?.clientHeight || 0) : undefined;
  //   ◎ 設定したい情報​
  // ・チーム名（属性：チームと紐づけたい）​
  // ・アイコン（属性：チームと紐づけたい）​
  // 　→属性に画像を持たせる？（テニスにも活きる？）​
  // ・試合日程​
  // ・試合の段階/Group​
  // ・試合結果​
  // ⇒ それぞれ、手動での運用を想定​
  // ※ 試合結果の表示・非表示はフラグで設定できるようにしたい
  const row = _.map(itemData.items, (item, idx) => {
    const startAt = item.startAt ? datetime(item.startAt, FORMAT, 'ja') : null;
    const endAt = item.endAt ? datetime(item.endAt, FORMAT, 'ja') : null;
    const now = datetime();

    const card = item.card || [];
    if (_.isEmpty(card) || card.length < 2) return null;
    const team1 = card[0] || {};
    const team2 = card[1] || {};

    // 日本語ライブ > 外国語ライブ
    const liveMeta = _.get(item, 'langJapaneseLiveMeta.ref_id')
      ? item.langJapaneseLiveMeta
      : _.get(item, 'langLocalLiveMeta.ref_id')
      ? item.langLocalLiveMeta
      : null;
    // 日本語vod > 外国語vod
    const vodMeta = _.get(item, 'langJapaneseCatchupMeta.ref_id')
      ? item.langJapaneseCatchupMeta
      : _.get(item, 'langLocalCatchupMeta.ref_id')
      ? item.langLocalCatchupMeta
      : null;
    let status = 7;
    if (item.calledOffFlag) {
      status = 1;
    } else if (item.suspendedFlag) {
      status = 2;
    } else if (liveMeta && !startAt) {
      status = 6;
    } else if (startAt && startAt.isAfter(now)) {
      status = 3;
    } else if ((endAt && endAt.isAfter(now)) || (startAt && !endAt && startAt.isBefore(now))) {
      status = 4;
    } else if (liveMeta || vodMeta) {
      status = 5;
    }

    let timeText = null;
    if (status === 3) {
      const d = startAt.format('M/D(dd)');
      const h = startAt.format('H');
      const m = startAt.format('mm');
      timeText = `${d}\n${h}:${m}`;
    } else {
      if (
        status === 7 &&
        (!_.isEmpty(item.langJapaneseLiveMeta) ||
          !_.isEmpty(item.langLocalLiveMeta) ||
          !_.isEmpty(item.langJapaneseCatchupMeta) ||
          !_.isEmpty(item.langLocalCatchupMeta))
      ) {
        // 遷移先メタが何かしらある場合は「見逃し視聴」
        status = 5;
      }
      timeText = STATUS_LABELS[status];
    }

    const active = status === 4;
    const scoreEl =
      item.displayScoreFlag && team1.score != null && team2.score != null ? (
        <div className="result">{`${team1.score} - ${team2.score}`}</div>
      ) : null;

    let links = [];
    const beforeLiveEnd = [3, 4, 6].includes(status);
    // 通常キャンバスでライブ中・見逃し・中断のみ
    const showPlayIcon = !props.showButton && [2, 4, 5].includes(status);
    let params: any, meta: any;

    // ライブ終了 or ライブメタがない & VOD公開中
    if ((!beforeLiveEnd || !liveMeta) && vodMeta) {
      params = { id: vodMeta.ref_id };
      meta = vodMeta;
    } else if (liveMeta) {
      params = { id: liveMeta.ref_id };
      meta = liveMeta;
    }
    let fullLinkProps: any;
    if (meta && params) {
      fullLinkProps = { to: routes.content, params, onClick: getOnClick(meta) };
    }

    const contentInner = (
      <React.Fragment>
        <div className="soccer-card__inner__match__order-team home">
          <div className="emblem">
            {/*
             // @ts-ignore TS2322 */}
            <BackgroundImage url={team1.image} />
          </div>
          {team1.name}
        </div>
        <div className={classnames('soccer-card__inner__match__order-game', { no_button: !props.showButton })}>
          {showPlayIcon ? <img src="/images/card-play.svg" className="play-circle" /> : null}
          {timeText ? (
            <div className={classnames('time', { with_icon: showPlayIcon })}>{reactNl2br(timeText)}</div>
          ) : null}
          {scoreEl}
        </div>
        <div className="soccer-card__inner__match__order-team away">
          {team2.name}
          <div className="emblem">
            {/*
             // @ts-ignore TS2322 */}
            <BackgroundImage url={team2.image} />
          </div>
        </div>
      </React.Fragment>
    );
    //パレット
    if (!props.showButton) {
      return fullLinkProps ? (
        <MainViewLink {...fullLinkProps} className={classnames('soccer-card__inner__match', { active })}>
          <div className="soccer-card__inner__match__order">{contentInner}</div>
        </MainViewLink>
      ) : (
        <div className="soccer-card__inner__match">
          <div className="soccer-card__inner__match__order">{contentInner}</div>
        </div>
      );
    }

    const hasFullCid =
      _.get(item, 'langJapaneseLiveMetaCid') ||
      _.get(item, 'langJapaneseCatchupMetaCid') ||
      _.get(item, 'langLocalCatchupMetaCid') ||
      _.get(item, 'langLocalLiveMetaCid');

    if (hasFullCid) {
      links.push(
        <MainViewLink
          {...fullLinkProps}
          className={classnames('soccer-card__inner__match__type__item', {
            disabled: !fullLinkProps,
          })}
        >
          フルで見る
        </MainViewLink>,
      );
    }

    if (_.get(item, 'highlightMetaCid')) {
      let highlightLinkProps: any;
      if (_.get(item, 'highlightMeta.ref_id')) {
        highlightLinkProps = {
          to: routes.content,
          params: { id: item.highlightMeta.ref_id },
          onClick: getOnClick(item.highlightMeta),
        };
      }
      links.push(
        <MainViewLink
          // @ts-ignore TS2322
          {...highlightLinkProps}
          className={classnames('soccer-card__inner__match__type__item', {
            disabled: !highlightLinkProps,
          })}
        >
          ハイライトで見る
        </MainViewLink>,
      );
    }
    return (
      <div className={classnames('soccer-card__inner__match', { active })}>
        <div className="soccer-card__inner__match__order">{contentInner}</div>
        <div className="soccer-card__inner__match__type">{links.map(link => link)}</div>
      </div>
    );
  }).filter(item => !!item);

  if (!row.length) return null;

  const style = {};
  if (props.height) {
    // @ts-ignore TS2339
    style.height = props.height;
  }
  return (
    <div className="card title-card soccer-card" style={style} ref={props.rootRef}>
      <div className="soccer-card__title" ref={headerRef}>
        <div className="soccer-card__title-row">
          {itemData.title}
          {itemData.legText ? <div className="soccer-card__title-leg">{itemData.legText}</div> : null}
        </div>
        {itemData.description ? <div className="soccer-card__title-description">{itemData.description}</div> : null}
      </div>
      <div className="soccer-card__inner" style={{ height: soccerCardInnerHeight }}>
        {row}
      </div>
    </div>
  );
};
SoccerCard.contextTypes = {
  history: PropTypes.object,
  getModelData: PropTypes.func,
};
export default SoccerCard;
