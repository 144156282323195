import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import url from 'url';
import _ from 'src/libs/util';

import WodPlayerCore from './WodPlayerCore';
import WodPlayerControls from './WodPlayerControls';
import Spinner from './Spinner';
import Bezel from './Bezel';
import ErrorView from './ErrorView';
import generateUuid from '../../../../common/generateUuid';
import playbackRule from '../../../../models/playbackRule';
import activeProfile from '../../../../utils/activeProfile';
import Axios from '../../../../common/Axios';
import GCastPlayerApp from '../../../../common/GCastPlayerApp';

import settingsDataStore from '../../../../utils/settingsDataStore';
import volumeDataStore from '../../../../utils/volumeDataStore';
import vuidDataStore from '../../../../utils/vuidDataStore';
import DisableCastModal from '../../../common/components/modal/DisableCastModal';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';

import * as ERROR from '../../../../constants/error';
import { DSID_KEY } from '../../../../constants/cookie';
import { renditionsPresets } from '../../../../constants/settings';
import { CONTENT_EVENTS } from '../../../../common/GtmApp';
import PageUpdateModal from '../modal/PageUpdateModal';
import { AUDIO_LANGUAGE, TEXT_TRACK_LANGUAGE, TEXT_TRACK_MODE } from '../../../../common/PlayerApp';
import PlayerSideListButton from './sidelist/SidelistButton';
import { PlaybackUsecase } from 'src/usecase/apps/PlaybackUsecase';
import { BffPlaybackApi } from 'src/api/BffPlaybackApi';

let VUID;
let DSID;

const playContext = function(context) {
  let data = _.clone(context);
  return {
    set: function(key, val) {
      data[key] = val;
      return this;
    },
    get: function(key) {
      return data[key];
    },
    has: function(key) {
      return data[key] !== undefined;
    },
  };
};

type WodPlayerProps = {
  [key: string]: any;
};
type WodPlayerState = {
  ended: boolean;
  paused: boolean;
  isAdMode: boolean;
  playStartCompleted?: boolean;
  [key: string]: any;
};

interface Player {
  on(eventName: string, callback: (e: any | null) => void): void;
  off(eventName: string, callback: (e: any | null) => void): void;
  localPlayer(): any;
  isGCasted(): boolean;
  getGCastDeviceName(): string;
}

class WodPlayer extends React.PureComponent<WodPlayerProps, WodPlayerState> {
  private _player: Player | null;
  private _isMounted: boolean = false;
  enableSeek: boolean;
  enablePause: boolean;

  static get contextTypes() {
    return {
      node: PropTypes.object,
      playerApp: PropTypes.object,
      fsApp: PropTypes.object,
      cookies: PropTypes.object,
      models: PropTypes.object,
      getModelData: PropTypes.func,
      watchPartyApp: PropTypes.object,
      isIframe: PropTypes.bool,
      gtmApp: PropTypes.object,
      routeHandler: PropTypes.object,
      history: PropTypes.object,
    };
  }

  static get childContextTypes() {
    return {
      playerApp: PropTypes.object,
    };
  }

  static get defaultProps() {
    return {
      allowLowPowerMode: true,
      autoplay: true,
      controls: true,
      metadata: {},
      isPV: false,
    };
  }

  static get propTypes() {
    return {
      activeVideo: PropTypes.object,
      allowLowPowerMode: PropTypes.bool,
      autoPlay: PropTypes.bool,
      beforePlayerClosing: PropTypes.func,
      className: PropTypes.string,
      config: PropTypes.object,
      controls: PropTypes.bool,
      controlsMainHitzoneElement: PropTypes.element,
      disabled: PropTypes.bool,
      engine: PropTypes.string,
      exitPlayer: PropTypes.func,
      mode: PropTypes.string,
      hasLargeDimensions: PropTypes.bool,
      hasPreplay: PropTypes.bool,
      mainVideoId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      metadata: PropTypes.object,
      onCastToTarget: PropTypes.func,
      onPlayerError: PropTypes.func,
      onPlayerLoadStart: PropTypes.func,
      onPlayerLoaded: PropTypes.func,
      onPlayerPause: PropTypes.func,
      onPlayerFirstplay: PropTypes.func,
      onPlayerEnded: PropTypes.func,
      onPlayerPlay: PropTypes.func,
      onPlayerReady: PropTypes.func,
      onPlayerPlaybackRateChange: PropTypes.func,
      onPlayerSurfaceClick: PropTypes.func,
      onPlayerTimeUpdate: PropTypes.func,
      onPlayerDurationChange: PropTypes.func,
      onPlayerTimeout: PropTypes.func,
      onPlayerPlaying: PropTypes.func,
      onPlayerAdsAdStarted: PropTypes.func,
      onPlayerAdsAdEnded: PropTypes.func,
      onPlayerAdBreakStart: PropTypes.func,
      onPlayerAdBreakEnd: PropTypes.func,
      onPlayerAdsPlay: PropTypes.func,
      onPlayerAdsPause: PropTypes.func,
      onPlayerAdsAdTimeUpdate: PropTypes.func,
      onPlayerVolumeChange: PropTypes.func,
      onPostplay: PropTypes.func,
      onUserActivityChanged: PropTypes.func,
      onPrepared: PropTypes.func,
      onClickCommentButton: PropTypes.func,
      commentActive: PropTypes.bool,
      watchPartyMode: PropTypes.bool,
      watchPartyType: PropTypes.string,
      playerContext: PropTypes.object,
      playNextTitle: PropTypes.func,
      playbackEnded: PropTypes.bool,
      postShowInfo: PropTypes.bool,
      restartPlayer: PropTypes.func,
      shouldFadePLayerControlsBar: PropTypes.bool,
      shouldRepositionSubtitlesWhenInactive: PropTypes.bool,
      showEpisodeSelector: PropTypes.bool,
      showTimedText: PropTypes.bool,
      spinnerClass: PropTypes.object,
      spinnerClassProps: PropTypes.object,
      staticConfiguration: PropTypes.object,
      staticElement: PropTypes.element,
      uiLabel: PropTypes.string,
      updateDimesions: PropTypes.object,
      videoId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      handlePlayerSideListModeChange: PropTypes.func,
    };
  }

  getChildContext() {
    return {
      // @ts-ignore TS2339
      playerApp: this._player,
    };
  }

  playbackUsecase: PlaybackUsecase;
  playerControlRef: React.RefObject<WodPlayerControls>;
  activeTimeoutId: NodeJS.Timeout;
  autoPlayErrorFlg?: boolean;
  __keepActive?: boolean;

  constructor(props, context) {
    super(props, context);
    this.dispose = this.dispose.bind(this);
    this.handleWindowUnload = this.handleWindowUnload.bind(this);
    this.onFocusWindow = this.onFocusWindow.bind(this);
    this.onBlurWindow = this.onBlurWindow.bind(this);
    // this.setRenditions = this.setRenditions.bind(this);
    this.onPlayerLoadedmetadata = this.onPlayerLoadedmetadata.bind(this);
    this.onPlayerLoadeddata = this.onPlayerLoadeddata.bind(this);
    this.onPlayerLoaded = this.onPlayerLoaded.bind(this);
    this.onPlayerReady = this.onPlayerReady.bind(this);
    this.onPlayerError = this.onPlayerError.bind(this);
    this.onPlayerIdle = this.onPlayerIdle.bind(this);
    this.onPlayerPlay = this.onPlayerPlay.bind(this);
    this.onPlayerPause = this.onPlayerPause.bind(this);
    this.onPlayerLoadstart = this.onPlayerLoadstart.bind(this);
    this.onPlayerPlayStartCompleted = this.onPlayerPlayStartCompleted.bind(this);
    this.onPlayerPlaying = this.onPlayerPlaying.bind(this);
    this.onPlayerAdsAdStarted = this.onPlayerAdsAdStarted.bind(this);
    this.onPlayerAdsAdEnded = this.onPlayerAdsAdEnded.bind(this);
    this.onPlayerAdModeStart = this.onPlayerAdModeStart.bind(this);
    this.onPlayerAdModeEnd = this.onPlayerAdModeEnd.bind(this);
    this.onPlayerAdBreakStart = this.onPlayerAdBreakStart.bind(this);
    this.onPlayerAdBreakEnd = this.onPlayerAdBreakEnd.bind(this);
    this.onPlayerAdsPlay = this.onPlayerAdsPlay.bind(this);
    this.onPlayerAdsPause = this.onPlayerAdsPause.bind(this);
    this.onPlayerAdsAdTimeUpdate = this.onPlayerAdsAdTimeUpdate.bind(this);
    this.onPlayerFirstplay = this.onPlayerFirstplay.bind(this);
    this.onPlayerRebuffering = this.onPlayerRebuffering.bind(this);
    this.onPlayerDurationChange = this.onPlayerDurationChange.bind(this);
    this.onPlayerPlaybackRateChange = this.onPlayerPlaybackRateChange.bind(this);
    this.onPlayerVolumeChange = this.onPlayerVolumeChange.bind(this);
    this.onPlayerMuted = this.onPlayerMuted.bind(this);
    this.onPlayerStalled = this.onPlayerStalled.bind(this);
    this.onPlayerEmptied = this.onPlayerEmptied.bind(this);
    this.onPlayerSuspend = this.onPlayerSuspend.bind(this);
    this.onPlayerWaiting = this.onPlayerWaiting.bind(this);
    this.onPlayerCanplay = this.onPlayerCanplay.bind(this);
    this.onPlayerSeeked = this.onPlayerSeeked.bind(this);
    this.onPlayerSeeking = this.onPlayerSeeking.bind(this);
    this.onPlayerCanplaythrough = this.onPlayerCanplaythrough.bind(this);
    this.onPlayerTimeUpdate = this.onPlayerTimeUpdate.bind(this);
    this.onPlayerSrcFallback = this.onPlayerSrcFallback.bind(this);
    this.onPlayerOutputRestricted = this.onPlayerOutputRestricted.bind(this);
    this.onPlayerTextTracksChange = this.onPlayerTextTracksChange.bind(this);
    this.onPlayerAudioTracksChange = this.onPlayerAudioTracksChange.bind(this);
    this.onPlayerEnterPictureInPicture = this.onPlayerEnterPictureInPicture.bind(this);
    this.onPlayerLeavePictureInPicture = this.onPlayerLeavePictureInPicture.bind(this);
    this.onPlayerEnded = this.onPlayerEnded.bind(this);
    this.onPlayerStateChange = this.onPlayerStateChange.bind(this);
    this.getFullScreenNode = this.getFullScreenNode.bind(this);
    this.beforeFullScreenChange = this.beforeFullScreenChange.bind(this);
    this.beforeEnterFullScreen = this.beforeEnterFullScreen.bind(this);
    this.beforeExitFullScreen = this.beforeExitFullScreen.bind(this);
    this.onFullScreenChange = this.onFullScreenChange.bind(this);
    this.onEnterFullScreen = this.onEnterFullScreen.bind(this);
    this.onExitFullScreen = this.onExitFullScreen.bind(this);
    this.toggleFullScreen = this.toggleFullScreen.bind(this);

    this.onClickVolume = this.onClickVolume.bind(this);
    this.onClickCastButton = this.onClickCastButton.bind(this);
    this.onClickPlay = this.onClickPlay.bind(this);
    this.onClickReload = this.onClickReload.bind(this);
    this.onClickSeekBack = this.onClickSeekBack.bind(this);
    this.onClickSeekForward = this.onClickSeekForward.bind(this);
    this.onClickVideoSkip = this.onClickVideoSkip.bind(this);
    this.onKeydownVideoSkip = this.onKeydownVideoSkip.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.showControlsOnMobileDevices = this.showControlsOnMobileDevices.bind(this);
    this.onClickTouchDeviceControl = this.onClickTouchDeviceControl.bind(this);
    this.togglePictureInPicture = this.togglePictureInPicture.bind(this);
    this.setKeepActive = this.setKeepActive.bind(this);
    this.updateCurrentScrubTime = this.updateCurrentScrubTime.bind(this);
    this.handleHoverControls = this.handleHoverControls.bind(this);
    this.onChangeQuality = this.onChangeQuality.bind(this);
    this.isEnding = this.isEnding.bind(this);
    this.initAudioTracksSetting = this.initAudioTracksSetting.bind(this);
    this.initTextTracksSetting = this.initTextTracksSetting.bind(this);
    this.initAudioAndTextTrack = this.initAudioAndTextTrack.bind(this);
    this.setWillPlayAd = this.setWillPlayAd.bind(this);
    // @ts-ignore TS2551
    this.renditions = renditionsPresets.default; // _.get(this.props, 'config.renditions');

    // @ts-ignore TS2339
    this.playerElement = null;

    const settingsData = settingsDataStore.get() || {};
    const textTrack = settingsData.textTrack;
    const audioTrack = settingsData.audioTrack;
    const playbackRate = props.playbackRate || settingsData.playbackRate || 1;
    // const playbackRate = props.playbackRate || 1;
    const quality = settingsData.quality || 0;

    // @ts-ignore TS2339
    this.seekBackCount = 0;
    // @ts-ignore TS2339
    this.seekForwardCount = 0;
    // @ts-ignore TS2339
    this.keyboardFocus = false;

    // @ts-ignore TS2339
    this.playAnalytics = {};

    // ボリュームの状態を復元
    const volumeData = volumeDataStore.get() || {};
    const muted = props.muted || volumeData.muted || false;
    const volume = props.volume || volumeData.volume || 1;

    let paused = props.autoplay === false;
    const browserInfo = context.getModelData('browserInfo');
    if (browserInfo.isSafari && parseInt(browserInfo.major, 10) >= 11) {
      paused = true;
    }

    this.state = {
      action: null,
      prepareLoading: false,
      // @ts-ignore TS2339
      playContext: this.props.playContext,
      activeVideo: _.clone(props.activeVideo),
      currentScrubTime: -1,
      audioTracks: [],
      textTracks: [],
      clearChain: true,
      config: {},
      error: null,
      forcePlaybackEngine: props.forcePlaybackEngine,
      handleSeekerbarMode: false,
      metadata: props.metadata,
      fetching: props.fetching,
      artwork: props.artwork,
      ready: false,
      paused: paused,
      ended: false,
      postplayFetched: false,
      staticConfiguration: props.staticConfiguration,
      uiState: '',
      muted: muted,
      volume: volume,
      textTrack: textTrack,
      audioTrack: audioTrack,
      initialTextTrack: null,
      initialAudioTrack: null,
      playbackRate: playbackRate,
      quality: quality,
      gCastLoading: false,
      errorView: props.errorView,
      initializedTextTracks: false,
      initializedAudioTracks: false,
      initializedAudioAndTextTracks: false,
      isAdMode: false,
      adDuration: 0,
      currentAdTime: 0,
      willPlayAd: props.shouldPlayAd,
    };

    // @ts-ignore TS2339
    this._player = props.player;
    // @ts-ignore TS2339
    if (!this._player) {
      // お試し再生
      // @ts-ignore TS2339
      this._player = context.playerApp;
      context.fsApp.getFullScreenNode = this.getFullScreenNode.bind(this);
    }

    // @ts-ignore TS2339
    this._player.setMetaId(props.id || _.get(props, 'metadata.id'));

    if (this._player.isGCasted()) {
      this.gCastRetrievePlayerId();

      // 連続再生時はローディング表示
      // @ts-ignore TS2339
      if (this._player.castPlayer().playerState() == GCastPlayerApp.PLAYER_STATE.UPNEXT) {
        // @ts-ignore TS2339
        this.state.gCastLoading = true;
      }
    }

    this.gCastStart = this.gCastStart.bind(this);
    this.gCastEnd = this.gCastEnd.bind(this);
    this.gCastError = this.gCastError.bind(this);
    this.gCastConnectedChange = this.gCastConnectedChange.bind(this);
    this.gCastChangeSettings = this.gCastChangeSettings.bind(this);
    this.sessionClose = this.sessionClose.bind(this);
    this.upnext = this.upnext.bind(this);
    this.beacon = this.beacon.bind(this);
    this.sendEvent = this.sendEvent.bind(this);
    this.autoPlayError = this.autoPlayError.bind(this);
    // @ts-ignore TS2339
    this._axios = new Axios();
    this.playbackUsecase = new PlaybackUsecase(new BffPlaybackApi({ xhr: true }));

    // @ts-ignore TS2339
    this.playerRef = React.createRef();
    this.playerControlRef = React.createRef();
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyboardFocus = this.handleKeyboardFocus.bind(this);

    this.startWaitingCurrentTimeCheck = this.startWaitingCurrentTimeCheck.bind(this);
    this.stopWaitingCurrentTimeCheck = this.stopWaitingCurrentTimeCheck.bind(this);
    this.checkWaitingCurrentTime = this.checkWaitingCurrentTime.bind(this);
    this.startCheckRebufferingTime = this.startCheckRebufferingTime.bind(this);
    this.stopCheckRebufferingTime = this.stopCheckRebufferingTime.bind(this);
    this.startCheckBlurTime = this.startCheckBlurTime.bind(this);
    this.stopCheckBlurTime = this.stopCheckBlurTime.bind(this);
    this.forceStopPlaying = this.forceStopPlaying.bind(this);
    this.focusOnPlayer = this.focusOnPlayer.bind(this);
    this.disableVideoTagTextTrack = this.disableVideoTagTextTrack.bind(this);

    this.enableSeek = false;
    this.enablePause = false;
  }

  componentDidMount() {
    window.addEventListener('unload', this.handleWindowUnload, false);
    window.addEventListener('focus', this.onFocusWindow, false);
    window.addEventListener('blur', this.onBlurWindow, false);

    this._player.on('gCastStart', this.gCastStart);
    this._player.on('gCastEnd', this.gCastEnd);
    this._player.on('gCastError', this.gCastError);
    this._player.on('gCastConnectedChange', this.gCastConnectedChange);
    this._player.on('gCastChangeSettings', this.gCastChangeSettings);
    this._player.on('sessionClose', this.sessionClose);
    this._player.on('upnext', this.upnext);
    this._player.on('beacon', this.beacon);
    // @ts-ignore TS2339
    this._player.on('sendEvent', this.sendEvent);
    this._player.on('autoPlayError', this.autoPlayError);
    this._player.on('idle', this.onPlayerIdle);
    this._player.on('play', this.onPlayerPlay);
    this._player.on('pause', this.onPlayerPause);
    this._player.on('volumechange', this.onPlayerVolumeChange);
    this._player.on('muted', this.onPlayerMuted);
    this._player.on('firstplay', this.onPlayerFirstplay);
    this._player.on('rebuffering', this.onPlayerRebuffering);
    this._player.on('ended', this.onPlayerEnded);
    this._player.on('loadstart', this.onPlayerLoadstart);
    this._player.on('playStartCompleted', this.onPlayerPlayStartCompleted);
    this._player.on('playing', this.onPlayerPlaying);
    this._player.on('ads-ad-started', this.onPlayerAdsAdStarted);
    this._player.on('ads-ad-ended', this.onPlayerAdsAdEnded);
    this._player.on('admodestart', this.onPlayerAdModeStart);
    this._player.on('admodeend', this.onPlayerAdModeEnd);
    this._player.on('adbreakstart', this.onPlayerAdBreakStart);
    this._player.on('adbreakend', this.onPlayerAdBreakEnd);
    this._player.on('ads-play', this.onPlayerAdsPlay);
    this._player.on('ads-pause', this.onPlayerAdsPause);
    this._player.on('ads-ad-timeupdate', this.onPlayerAdsAdTimeUpdate);
    this._player.on('loadedmetadata', this.onPlayerLoadedmetadata);
    this._player.on('loadeddata', this.onPlayerLoadeddata);
    this._player.on('loaded', this.onPlayerLoaded);
    this._player.on('durationchange', this.onPlayerDurationChange);
    this._player.on('ratechange', this.onPlayerPlaybackRateChange);
    this._player.on('stalled', this.onPlayerStalled);
    this._player.on('emptied', this.onPlayerEmptied);
    this._player.on('suspend', this.onPlayerSuspend);
    this._player.on('waiting', this.onPlayerWaiting);
    this._player.on('canplay', this.onPlayerCanplay);
    this._player.on('seeked', this.onPlayerSeeked);
    this._player.on('seeking', this.onPlayerSeeking);
    this._player.on('canplaythrough', this.onPlayerCanplaythrough);
    this._player.on('timeupdate', this.onPlayerTimeUpdate);
    this._player.on('src-fallback', this.onPlayerSrcFallback);
    this._player.on('textTracksChange', this.onPlayerTextTracksChange);
    this._player.on('audioTracksChange', this.onPlayerAudioTracksChange);
    this._player.on('enterpictureinpicture', this.onPlayerEnterPictureInPicture);
    this._player.on('leavepictureinpicture', this.onPlayerLeavePictureInPicture);
    this._player.on('output-restricted', this.onPlayerOutputRestricted);
    this._player.on('beforeFullScreenChange', this.beforeFullScreenChange);
    this._player.on('onFullScreenChange', this.onFullScreenChange);

    // @ts-ignore TS2339
    this.setState({ isFullScreen: this._player.getIsFullScreen() });

    this._isMounted = true;
    this.prepareToPlay(this.props, this.context);
    if (!this.context.isIframe) {
      this.focusOnPlayer();
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const newState = {};
    // @ts-ignore TS2339
    if (nextProps.playContext !== this.props.playContext) {
      // @ts-ignore TS2339
      newState.playContext = nextProps.playContext;
    }
    // @ts-ignore TS2339
    if (nextProps.activeVideo !== this.props.activeVideo) {
      // @ts-ignore TS2339
      newState.activeVideo = nextProps.activeVideo;
    }
    // @ts-ignore TS2339
    if (nextProps.metadata !== this.props.metadata) {
      // @ts-ignore TS2339
      newState.metadata = nextProps.metadata;
      // @ts-ignore TS2339
      this._player.setMetaId(nextProps.metadata.id);
    }
    // @ts-ignore TS2339
    if (nextProps.fetching !== this.props.fetching) {
      // @ts-ignore TS2339
      newState.fetching = nextProps.fetching;
    }
    // @ts-ignore TS2339
    if (nextProps.artwork !== this.props.artwork) {
      // @ts-ignore TS2339
      newState.artwork = nextProps.artwork;
    }
    // @ts-ignore TS2339
    if (nextProps.videoSkip !== this.props.videoSkip) {
      // @ts-ignore TS2339
      newState.autoHideVideoSkip = false;
    }
    // @ts-ignore TS2339
    if (!this._player.isGCasted() && nextProps.errorView !== this.props.errorView) {
      // @ts-ignore TS2339
      newState.errorView = nextProps.errorView;
    }
    this.setState(newState, () => {
      // @ts-ignore TS2339
      if (newState.metadata && (this.pendingGCastStart || this._player.gCastAutoplay())) {
        // Cast中に次のエピソード再生時
        // @ts-ignore TS2339
        if (this._player.gCastAutoplay()) {
          // @ts-ignore TS2339
          this._player.clickGCastButton();
          // @ts-ignore TS2339
          this._player.gCastAutoplay(false);
        }
        this.gCastStart();
      } else if (
        this._player.isGCasted() &&
        // @ts-ignore TS2339
        this._player.castPlayer().playerState() == GCastPlayerApp.PLAYER_STATE.ENDED &&
        // @ts-ignore TS2339
        !this.state.ended &&
        // @ts-ignore TS2339
        !this.state.gCastLoading
      ) {
        // Cast中にもう一度再生ボタン押下時
        // @ts-ignore TS2339
        this._player.clickGCastButton();
        this.gCastStart();
      }
    });
  }

  componentWillUpdate(newProps, newState) {
    // @ts-ignore TS2339
    if (this.props.onUiStateChange && this.state.uiState !== newState.uiState) {
      // @ts-ignore TS2339
      this.props.onUiStateChange(newState.uiState);
    }
  }

  componentDidUpdate(beforeProps, beforeState: WodPlayerState) {
    if (beforeState.paused != this.state.paused) {
      if (!this.state.paused && this.props.onPlayerPlaying) {
        // @ts-ignore TS2339
        this.props.onPlayerPlay();

        // endedした後のpauseはスルーする
      } else if (this.state.paused && this.props.onPlayerPause && !this.state.ended) {
        this.addResume(false);
        // @ts-ignore TS2339
        this.props.onPlayerPause();
      }
    }
    // @ts-ignore TS2339
    if (this.state.willPlayAd) {
      if (this.state.isAdMode && !beforeState.isAdMode) {
        // @ts-ignore TS2339
        this.setState({ willPlayAd: false });
      }
    }

    // @ts-ignore TS2339
    if (beforeState.ended != this.state.ended) {
      this.addResume(true);
      // @ts-ignore TS2339
      if (this.state.ended && this.props.onPlayerEnded) {
        // @ts-ignore TS2339
        this.props.onPlayerEnded();
      }
    }

    // @ts-ignore TS2339
    if (beforeState.seeking != this.state.seeking) {
      // @ts-ignore TS2339
      if (this.state.seeking) {
        // @ts-ignore TS2339
        if (this.context.playerApp) this._player.trackEvent('seekStart');
      } else {
        // @ts-ignore TS2339
        if (this.context.playerApp) this._player.trackEvent('seekEnd');
      }
      // @ts-ignore TS2339
      if (this.state.seeking && this.props.onPlayerSeeking) {
        // @ts-ignore TS2339
        this.props.onPlayerSeeking();
        // @ts-ignore TS2339
        if (this.state.handleSeekerbarMode && this.state.currentScrubTime !== -1) {
          this.setHandleSeekerBarMode(false);
        }
      }
    }
    // @ts-ignore TS2339
    if (beforeState.playStartCompleted != this.state.playStartCompleted) {
      // @ts-ignore TS2339
      if (this.state.playStartCompleted) {
        // @ts-ignore TS2339
        this.__playStartCompletedTime = Date.now();
        if (this.onPlayerFirstplay) {
          this.onPlayerFirstplay(false);
        }
      }
    }

    if (
      // @ts-ignore TS2339
      beforeProps.enableAutoplay != this.props.enableAutoplay ||
      // @ts-ignore TS2339
      beforeState.subtitleLanguage != this.state.subtitleLanguage
    ) {
      this.gCastSettings();
    }

    // @ts-ignore TS2339
    if (this.state.uiState === 'active') {
      // @ts-ignore TS2339
      if (this.__keepActive) {
        // @ts-ignore TS2339
        if (this.activeTimeoutId) {
          // @ts-ignore TS2339
          clearTimeout(this.activeTimeoutId);
          // @ts-ignore TS2339
          delete this.activeTimeoutId;
        }
        // @ts-ignore TS2339
      } else if (beforeState.uiState !== this.state.uiState) {
        // @ts-ignore TS2339
        if (!this.activeTimeoutId) {
          // @ts-ignore TS2339
          this.activeTimeoutId = setTimeout(() => {
            this.setState({ uiState: 'inactive' });
          }, 3000);
        }
      }
    } else {
      // @ts-ignore TS2339
      if (this.activeTimeoutId) {
        // @ts-ignore TS2339
        clearTimeout(this.activeTimeoutId);
        // @ts-ignore TS2339
        delete this.activeTimeoutId;
      }
    }
    // @ts-ignore TS2339
    if (this.state.rebuffering !== beforeState.rebuffering) {
      // @ts-ignore TS2339
      if (this.state.rebuffering) {
        this.startCheckRebufferingTime();
      } else {
        this.stopCheckRebufferingTime();
      }
    }
    // @ts-ignore TS2339
    if (this.state.initializedAudioTracks && this.state.initializedAudioTracks !== beforeState.initializedAudioTracks) {
      // 音声によって強制字幕の言語が変わるので音声の初期化が終わってから字幕を設定する。
      this.initTextTracksSetting();
    }
    // @ts-ignore TS2339
    const initialized = this.state.initializedAudioTracks && this.state.initializedTextTracks;
    const prevInitialized = beforeState.initializedAudioTracks && beforeState.initializedTextTracks;

    if (initialized && initialized !== prevInitialized) {
      this.initAudioAndTextTrack();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('unload', this.handleWindowUnload, false);
    window.removeEventListener('focus', this.onFocusWindow, false);
    window.removeEventListener('blur', this.onBlurWindow, false);
    // @ts-ignore TS2339
    if (this.props.onUiStateChange) {
      // @ts-ignore TS2339
      this.props.onUiStateChange('');
    }
    // @ts-ignore TS2339
    if (this.activeTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.activeTimeoutId);
      // @ts-ignore TS2339
      delete this.activeTimeoutId;
    }

    // @ts-ignore TS2339
    if (this.countResetTimerId) {
      // @ts-ignore TS2339
      clearTimeout(this.countResetTimerId);
      // @ts-ignore TS2339
      delete this.countResetTimerId;
    }

    // @ts-ignore TS2339
    if (this.playAnalytics.timerId) {
      // @ts-ignore TS2339
      clearInterval(this.playAnalytics.timerId);
      // @ts-ignore TS2339
      delete this.playAnalytics.timerId;
    }

    this.stopWaitingCurrentTimeCheck();
    this.stopCheckRebufferingTime();
    this.stopCheckBlurTime();

    // 遷移時はPIPをやめる
    // @ts-ignore TS2339
    if (this._player.isInPictureInPicture()) {
      // @ts-ignore TS2339
      this._player.exitPictureInPicture();
    }

    this._player.off('gCastStart', this.gCastStart);
    this._player.off('gCastEnd', this.gCastEnd);
    this._player.off('gCastError', this.gCastError);
    this._player.off('gCastConnectedChange', this.gCastConnectedChange);
    this._player.off('gCastChangeSettings', this.gCastChangeSettings);
    this._player.off('sessionClose', this.sessionClose);
    this._player.off('upnext', this.upnext);
    this._player.off('beacon', this.beacon);
    this._player.off('autoPlayError', this.autoPlayError);

    this._player.off('idle', this.onPlayerIdle);
    this._player.off('play', this.onPlayerPlay);
    this._player.off('pause', this.onPlayerPause);
    this._player.off('volumechange', this.onPlayerVolumeChange);
    this._player.off('muted', this.onPlayerMuted);
    this._player.off('firstplay', this.onPlayerFirstplay);
    this._player.off('rebuffering', this.onPlayerRebuffering);
    this._player.off('ended', this.onPlayerEnded);
    this._player.off('loadstart', this.onPlayerLoadstart);
    this._player.off('playStartCompleted', this.onPlayerPlayStartCompleted);
    this._player.off('playing', this.onPlayerPlaying);
    this._player.off('ads-ad-started', this.onPlayerAdsAdStarted);
    this._player.off('ads-ad-ended', this.onPlayerAdsAdEnded);
    this._player.off('admodestart', this.onPlayerAdModeStart);
    this._player.off('admodeend', this.onPlayerAdModeEnd);
    this._player.off('adbreakstart', this.onPlayerAdBreakStart);
    this._player.off('adbreakend', this.onPlayerAdBreakEnd);
    this._player.off('ads-play', this.onPlayerAdsPlay);
    this._player.off('ads-pause', this.onPlayerAdsPause);
    this._player.off('ads-ad-timeupdate', this.onPlayerAdsAdTimeUpdate);
    this._player.off('loadedmetadata', this.onPlayerLoadedmetadata);
    this._player.off('loadeddata', this.onPlayerLoadeddata);
    this._player.off('loaded', this.onPlayerLoaded);
    this._player.off('durationchange', this.onPlayerDurationChange);
    this._player.off('ratechange', this.onPlayerPlaybackRateChange);
    this._player.off('stalled', this.onPlayerStalled);
    this._player.off('emptied', this.onPlayerEmptied);
    this._player.off('suspend', this.onPlayerSuspend);
    this._player.off('waiting', this.onPlayerWaiting);
    this._player.off('canplay', this.onPlayerCanplay);
    this._player.off('seeked', this.onPlayerSeeked);
    this._player.off('seeking', this.onPlayerSeeking);
    this._player.off('canplaythrough', this.onPlayerCanplaythrough);
    this._player.off('timeupdate', this.onPlayerTimeUpdate);
    this._player.off('src-fallback', this.onPlayerSrcFallback);
    this._player.off('textTracksChange', this.onPlayerTextTracksChange);
    this._player.off('audioTracksChange', this.onPlayerAudioTracksChange);
    this._player.off('enterpictureinpicture', this.onPlayerEnterPictureInPicture);
    this._player.off('leavepictureinpicture', this.onPlayerLeavePictureInPicture);
    this._player.off('output-restricted', this.onPlayerOutputRestricted);

    this._isMounted = false;
    this.dispose();

    // @ts-ignore TS2339
    if (this.props.onMounted) this.props.onMounted(false);
  }

  disableVideoTagTextTrack() {
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isSafari) return;
    const video = _.get(document.getElementsByTagName('video'), '0');
    // マニフェストのDEFAULTがtrueだと、safariでOSの字幕が出てしまうため回避策
    const textTracks = _.get(video, 'textTracks') || {};
    // @ts-ignore TS2339
    if (textTracks.length) {
      // @ts-ignore TS2339
      for (let i = 0; i < textTracks.length; i++) {
        const textTrack = textTracks[i];
        if (_.get(textTrack, 'mode') === 'showing') {
          textTrack.mode = 'disabled';
        }
      }
    }
  }

  handleWindowUnload() {
    // @ts-ignore TS2339
    this.__unloading = true;
    this.dispose();
  }

  onFocusWindow() {
    this.stopCheckBlurTime();
  }

  onBlurWindow() {
    this.startCheckBlurTime();
  }

  /**
   * プレーヤーを操作時に状態を更新する。その後、状態をリセットする。
   * @param {string} actionName プレーヤーのコントロール名(e.g. play, pause, forward)
   */
  triggerActionState(actionName) {
    this.setState({ action: actionName }, () => {
      this.setState({ action: null });
    });
  }

  /**
   * `triggerActionState`に渡す音量に関するアクション名を取得する。
   * @param {number} volume
   * @returns {string} actionName
   */
  getVolumeActionName(volume) {
    if (volume >= 0.5) {
      return 'volume-high';
    } else if (volume > 0) {
      return 'volume-mind';
    } else {
      return 'mute';
    }
  }

  updateCurrentScrubTime(currentScrubTime) {
    this.setState({ currentScrubTime });
  }

  adjustSeekerBarPosition() {
    // @ts-ignore TS2339
    this.baseTime = this.baseTime != null ? this.baseTime : parseInt(this._player.currentTime());
    // @ts-ignore TS2339
    const isLiveVideo = this._player.duration() === Infinity;
    // @ts-ignore TS2339
    const seekableStart = isLiveVideo ? this._player.seekable().start(0) : 0;
    // @ts-ignore TS2339
    const seekableEnd = isLiveVideo ? this._player.seekable().end(0) : this._player.duration();

    // @ts-ignore TS2339
    let updateScrubTime = this.baseTime + 10 * (this.seekForwardCount - this.seekBackCount);

    if (updateScrubTime < seekableStart) {
      // @ts-ignore TS2339
      updateScrubTime = this.baseTime = 0;
      this.resetSeekCount();
    } else if (updateScrubTime > seekableEnd) {
      // 再生終了時間から少しずらさないと決定時にscrubTimeが更新されない
      // @ts-ignore TS2339
      updateScrubTime = this.baseTime = seekableEnd - 0.1;

      this.resetSeekCount();
    }
    this.updateCurrentScrubTime(updateScrubTime);
  }

  moveToCurrentScrubTime() {
    // @ts-ignore TS2339
    if (this.state.currentScrubTime != -1) {
      // @ts-ignore TS2339
      this._player.currentTime(this.state.currentScrubTime);
      this.updateCurrentScrubTime(-1);
    }
    this.setHandleSeekerBarMode(false);
  }

  resetSeekCount() {
    // @ts-ignore TS2339
    this.seekForwardCount = this.seekBackCount = 0;
  }

  setHandleSeekerBarMode(isActive = false) {
    this.resetSeekCount();
    // @ts-ignore TS2339
    this.baseTime = null;
    // @ts-ignore TS2339
    if (this.countResetTimerId) {
      // @ts-ignore TS2339
      clearTimeout(this.countResetTimerId);
      // @ts-ignore TS2339
      this.countResetTimerId = null;
    }
    this.updateCurrentScrubTime(-1);
    this.setKeepActive(isActive);
    this.setState({ handleSeekerbarMode: isActive });
  }

  /**
   * メディアプレイヤーのショートカットキー
   * @param {Event} event 入力キー
   */
  handleKeyDown(e) {
    const browserInfo = this.context.getModelData('browserInfo');

    // 入力中の場合は有効にしない
    if (
      // @ts-ignore TS2339
      !this.state.error &&
      // @ts-ignore TS2339
      !this.props.playerModal &&
      // @ts-ignore TS2339
      this.state.ready &&
      !browserInfo.isIOS &&
      !browserInfo.isAndroid &&
      document.activeElement.tagName !== 'INPUT'
    ) {
      this.onPlayerKeyDown();
      if (!e.defaultPrevented) {
        // Left Arrows

        if (e.which === 37 && this._player && this.enableSeek && !this.state.isAdMode) {
          // ウォッチパティーを参加する場合
          if (this.context.watchPartyApp.isUser()) {
            return;
          }

          e.preventDefault();
          // @ts-ignore TS2339
          this.seekBackCount++;

          // 一定時間内経つとカウントリセット
          // @ts-ignore TS2339
          if (!this.countResetTimerId && !this.state.handleSeekerbarMode) {
            // @ts-ignore TS2339
            this.countResetTimerId = setTimeout(() => {
              this.resetSeekCount();
              // @ts-ignore TS2339
              this.countResetTimerId = null;
            }, 500);
          }

          // @ts-ignore TS2339
          if (this.state.handleSeekerbarMode) {
            this.controlSeekerBar(e);
          } else {
            // @ts-ignore TS2339
            if (this.seekBackCount >= 2) {
              this.setHandleSeekerBarMode(true);
            }
            // 2回までは10秒スキップ
            this.onClickSeekBack(e);
            // @ts-ignore TS2339
            this.props.player.currentTime(this.props.player.currentTime() - 10);
          }

          // Right Arrows
        } else if (e.which === 39 && this._player && this.enableSeek && !this.state.isAdMode) {
          // ウォッチパティーを参加する場合
          if (this.context.watchPartyApp.isUser()) {
            return;
          }

          const isButtonDisabled = Array.from(
            this.playerControlRef.current?.buttonRefs?.['step-forward-10']?.current?.classList,
          ).includes('disabled');
          // @ts-ignore TS2339
          if (isButtonDisabled && !this.state.handleSeekerbarMode) {
            return;
          }

          e.preventDefault();
          // @ts-ignore TS2339
          this.seekForwardCount++;

          // 一定時間内経つとカウントリセット
          // @ts-ignore TS2339
          if (!this.countResetTimerId && !this.state.handleSeekerbarMode) {
            // @ts-ignore TS2339
            this.countResetTimerId = setTimeout(() => {
              this.resetSeekCount();
              // @ts-ignore TS2339
              this.countResetTimerId = null;
            }, 500);
          }

          // @ts-ignore TS2339
          if (this.state.handleSeekerbarMode) {
            this.controlSeekerBar(e);
          } else {
            // @ts-ignore TS2339
            if (this.seekForwardCount >= 2) {
              // 移動モードを利用可能にし、通常のスキップを利用不可にする
              this.setHandleSeekerBarMode(true);
            }
            // 2回までは10秒スキップ
            this.onClickSeekForward(e);
            // @ts-ignore TS2339
            this.props.player.currentTime(this.props.player.currentTime() + 10);
          }

          // Down Arrows(40) or Up Arrows(38)
          // @ts-ignore TS2339
        } else if ((e.which === 40 || e.which === 38) && this._player) {
          e.preventDefault();
          // @ts-ignore TS2339
          this._player.muted(false);
          const duration = e.which == 38 ? 0.05 : -0.05;
          // @ts-ignore TS2339
          const volume = this._player.volume() + duration;
          // @ts-ignore TS2339
          this._player.volume(_.max([0, volume]));
          this.triggerActionState(this.getVolumeActionName(volume));

          // space or enter
        } else if (e.which === 32 || e.which === 13) {
          // ウォッチパティーを参加する場合
          if (this.context.watchPartyApp.isUser()) {
            return;
          }

          if (
            // @ts-ignore TS2339
            this.keyboardFocus &&
            // @ts-ignore TS2339
            this.playerControlRef.current.controlButtonsElement.contains(document.activeElement) &&
            document.activeElement.getElementsByClassName('scrubber-bar').length == 0
          ) {
            // PlayerControl内のボタンにフォーカスが当たってたらそっちを優先(ProgressBarを除く)
            return;
          }
          e.preventDefault();
          // @ts-ignore TS2339
          if (this.state.handleSeekerbarMode) {
            this.controlSeekerBar(e);
            // @ts-ignore TS2339
          } else if (this.enablePause) {
            this.onClickPlay();
          } else {
            return;
          }

          // m Muted
        } else if (e.which === 77) {
          e.preventDefault();
          this.onClickVolume();

          // f player size
        } else if (e.which === 70) {
          e.preventDefault();
          this.toggleFullScreen();

          // s Skip
          // @ts-ignore TS2339
        } else if (e.which === 83 && this.props.videoSkip && _.isEmpty(this.props.nextEpisode)) {
          e.preventDefault();
          // @ts-ignore TS2554
          this.onClickVideoSkip();

          // esc
          // フルスクリーン中はブラウザのショートカット機能が優先される
        } else if (e.key === 'Escape') {
          // @ts-ignore TS2339
          if (browserInfo.isIE && this.state.isFullScreen) {
            // IEだけフルスクリーンの仕様が違うのでここで処理を行う
            this.toggleFullScreen();
            // @ts-ignore TS2339
          } else if (this.state.handleSeekerbarMode) {
            this.setHandleSeekerBarMode(false);
          }
        }
      }
    }
  }

  handleKeyboardFocus(keyboardFocus) {
    // @ts-ignore TS2339
    this.keyboardFocus = keyboardFocus;
  }

  /**
   * キーボードでシーカバーを操作する機能
   *
   * @param {Event} event 入力キー
   */
  controlSeekerBar(e) {
    switch (e.which) {
      case 37: // Left Arrows
      case 39: // Right Arrows
        this.adjustSeekerBarPosition();
        break;
      case 32: // Space
      case 13: // Enter
        this.moveToCurrentScrubTime();
        break;
      default:
        throw new Error(`Keyboard not available with the key : ${e.key}`);
    }
  }

  gCastStart() {
    //console.log('Player: gCastStart');

    // キャスト制限
    // @ts-ignore TS2339
    if (this.state.playContext && this.state.playContext.get('playbackRule').enableChromecast === false) {
      this.setState({ playStartCompleted: false, error: null, errorView: null });
      // @ts-ignore TS2339
      this.props.showModal(<DisableCastModal closeModal={this.props.closeModal} />);
      return;
    }

    this.setState({ gCastLoading: true });

    // @ts-ignore TS2339
    const metaId = this.props.id || _.get(this.state, 'metadata.id');
    if (!metaId) {
      // @ts-ignore TS2339
      this.pendingGCastStart = true;
      return;
    }

    // フルスクリーンなら戻す
    // @ts-ignore TS2339
    if (this.state.isFullScreen) {
      // @ts-ignore TS2339
      this._player.exitFullScreen();
    }

    this.addResume();

    const tokenService = _.assign({}, this.context.getModelData('services', 'tokenManager'));
    const authContext = this.context.getModelData('authContext');
    // @ts-ignore TS2339
    this._player
      // @ts-ignore TS2339
      .onetimeToken(tokenService, authContext)
      .then(token => {
        // @ts-ignore TS2554
        const profile = activeProfile(this.context.models);
        const requestParams = {
          token: token,
          profileId: _.get(profile, 'id'),
          // @ts-ignore TS2339
          autoAdvance: this.props.enableAutoplay,
          metaId: metaId,
          deviceCode: this.context.getModelData('browserInfo', 'deviceCode'),
          appId: 1,
          // @ts-ignore TS2339
          playbackRate: this.state.playbackRate,
          // locale: this.context.i18n.language,
          // subtitleLanguage: this.state.subtitleLanguage,
          // @ts-ignore TS2339
          subtitleLanguage: this.state.textTrack,
          // @ts-ignore TS2339
          audioLanguage: this.state.audioTrack,
        };
        const mediaId = _.get(this.context.routeHandler, 'query.m', null);
        if (mediaId) {
          // @ts-ignore TS2339
          requestParams.mediaId = mediaId;
        }
        // @ts-ignore TS2339
        if (this.castResumePoint) {
          // @ts-ignore TS2339
          requestParams.resumePoint = this.castResumePoint;
        }
        // @ts-ignore TS2339
        this._player.gCast(requestParams);
        this.setState({ playStartCompleted: false, error: null, errorView: null });
      })
      .catch(e => {
        console.log(e);
      });
  }

  gCastSettings() {
    // console.log('WodPlayer: gCastSettings');
    // @ts-ignore TS2339
    const settings = this._player.settings();
    if (settings) {
      // settings.locale = this.context.i18n.language;
      // @ts-ignore TS2339
      settings.autoAdvance = this.props.enableAutoplay;
      // settings.subtitleLanguage = this.state.subtitleLanguage;
      // @ts-ignore TS2339
      settings.subtitleLanguage = this.state.textTrack;
      // @ts-ignore TS2339
      settings.audioLanguage = this.state.audioTrack;
      // @ts-ignore TS2339
      this._player.settings(settings);
    }
  }

  gCastChangeSettings() {
    // console.log('WodPlayer: gCastChangeSettings');
    this.forceUpdate();
  }

  gCastEnd(option) {
    // console.log('WodPlayer: gCastEnd');
    const playbackSessionId = option.playbackSessionId;
    if (playbackSessionId) {
      const playbackService = Object.assign({}, this.context.getModelData('services', 'playback'));
      const authContext = this.context.getModelData('authContext');
      // @ts-ignore TS2339
      this._player.sessionClose(playbackService, authContext, playbackSessionId, false);
    }

    // pvページ以外はスマホは全てアプリ誘導
    // @ts-ignore TS2339
    if (this.isSpBrowser && !this.props.isPV) {
      const newState = {
        playContext: playContext(this.context)
          .set('video', {})
          .set('playbackRule', {})
          .set('session', {}),
      };
      if (this._isMounted) this.setState(newState);
      else this.state = Object.assign(newState);
      return;
    }

    // @ts-ignore TS2339
    if (this.props.onGCastEnd) {
      // @ts-ignore TS2339
      this.props.onGCastEnd(option);
    } else {
      // お試し再生時に再認証させる必要がある
      this.prepareToPlay(this.props, this.context);
    }
  }

  gCastError(obj) {
    // console.log('WodPlayer: gCastError', obj);
    this.setState({ paused: true, rebuffering: false, playStartCompleted: false, gCastLoading: false });
  }

  gCastConnectedChange(gCastConnected) {
    // console.log('WodPlayer: gCastConnectedChange', gCastConnected)
    // @ts-ignore TS2339
    if (this._player.localPlayer()) {
      // @ts-ignore TS2339
      this.castResumePoint = this._player.localPlayer().currentTime();
    }
    this.gCastRetrievePlayerId();
    this.setState({ gCastConnected });
  }

  gCastRetrievePlayerId() {
    if (this._player.isGCasted()) {
      const authContext = this.context.getModelData('authContext');
      const userId = _.get(authContext, 'id');
      // @ts-ignore TS2339
      this._player
        // @ts-ignore TS2339
        .retrievePlayerId(userId)
        .then(() => {
          // @ts-ignore TS2339
          if (this._player.isActiveGCasted() && this._player.isSelfGCasted(userId, true) && !this._player.paused()) {
            this.setState({ paused: false, playStartCompleted: true });
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  upnext() {
    // @ts-ignore TS2339
    if (this.props.onClickPlayNext) {
      // @ts-ignore TS2339
      this.props.onClickPlayNext();
    }
  }

  beacon(params) {
    const analytics = _.get(this.state, 'activeVideo.analytics', {});
    // @ts-ignore TS2339
    this._player.beacon(analytics, params);
  }

  sendEvent(path, params, async) {
    //console.log('Player: sendEvent', path, params);
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    const activeVideo = _.get(this.state, 'activeVideo', {});
    const trackService = Object.assign({}, this.context.getModelData('services', 'playbacktracking'));
    // @ts-ignore TS2339
    this._player.sendEvent(
      path,
      params,
      browserInfo,
      profile,
      // @ts-ignore TS2339
      this.state.isFullScreen,
      // @ts-ignore TS2339
      this.state.playbackRate,
      activeVideo,
      trackService,
      async,
    );
  }

  autoPlayError() {
    // console.log('WodPlayer: autoPlayError');
    // ウォッチパティーを参加する場合
    if (!this.context.watchPartyApp.getUser() && this.context.watchPartyApp.isUser()) {
      // WP入室前にautoplayできない時はミュートにしてソースから再生できるようにしておく
      // 参加者モーダルをユーザーが操作したタイミングでミュートを解除する
      // WP入室後、masterがリプレイする際にエラーが発生してもミュートにしない
      // @ts-ignore TS2339
      this._player.localPlayer().muted(true);
    }
    this.autoPlayErrorFlg = true;
    // console.log('WodPlayer paused true')
    const newState = { loading: false, paused: true, seeking: false };
    // このタイミングの以前に発生したエラーは再生可能なので消しておく
    if (_.get(this.state, 'error.dcode') === 'S-3' || _.get(this.state, 'error.dcode') === 'S-4') {
      // @ts-ignore TS2339
      newState.error = null;
      // @ts-ignore TS2339
      newState.errorView = null;
    }
    this.setState(newState);
  }

  sessionClose() {
    const playbackService = Object.assign({}, this.context.getModelData('services', 'playback'));
    const authContext = this.context.getModelData('authContext');
    // @ts-ignore TS2339
    const playbackSessionId = this._player.getPlaybackSessionId(
      _.get(this.state, 'activeVideo.session.id'),
      _.get(this.state, 'activeVideo.analytics', {}),
      // @ts-ignore TS2339
      this.state.playContext,
    );
    // @ts-ignore TS2339
    this._player.sessionClose(playbackService, authContext, playbackSessionId, false);
  }

  setRenditions(data, bitrateType) {
    // console.log('setRenditions')

    // デフォルトを設定
    if (bitrateType === 'vbr') {
      let renditions = _.get(this.props, 'config.vbr_renditions.default');
      // @ts-ignore TS2551
      if (renditions) this.renditions = renditions;
    }

    // プロファイル毎の設定
    if (_.get(data, 'profile')) {
      // console.log(data.profile)
      let renditions = _.get(this.props, 'config.vbr_renditions.' + data.profile);
      // @ts-ignore TS2551
      if (renditions) this.renditions = renditions;
    }
  }

  onMouseEnter(e) {
    this.setState({ uiState: 'active' });
  }

  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (this.activeTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.activeTimeoutId);
      // @ts-ignore TS2339
      delete this.activeTimeoutId;
    }
    this.setState({ uiState: 'inactive' });
  }

  onMouseMove() {
    // @ts-ignore TS2339
    if (this.activeTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.activeTimeoutId);
      // @ts-ignore TS2339
      delete this.activeTimeoutId;
    }
    this.setState({ uiState: 'active' });
    // @ts-ignore TS2339
    if (!this.__keepActive) {
      // @ts-ignore TS2339
      this.activeTimeoutId = setTimeout(() => {
        this.setState({ uiState: 'inactive' });
      }, 3000);
    }
  }

  showControlsOnMobileDevices(e) {
    this.setState({ uiState: 'active' });
  }

  onClickTouchDeviceControl(e) {
    if (this.isPlayStarted) {
      if (this.activeTimeoutId) {
        clearTimeout(this.activeTimeoutId);
        delete this.activeTimeoutId;
      }
      this.setState({ uiState: 'active' });
      // @ts-ignore TS2339
      if (!this.__keepActive) {
        // @ts-ignore TS2339
        this.activeTimeoutId = setTimeout(() => {
          this.setState({ uiState: 'inactive' });
        }, 3000);
      }
    } else {
      this.onClickPlay();
    }
  }

  get enableTextTracks() {
    // @ts-ignore TS2339
    return _.concat([this.noneTextTrack], this._player.textTracks());
  }

  get enableAudioTracks() {
    // @ts-ignore TS2339
    return this._player.audioTracks() || [];
  }

  //非表示
  get noneTextTrack() {
    // @ts-ignore TS2339
    const noneFnTextTracks = this._player.noneFnTextTracks();
    const self = this;
    return {
      id: 'none',
      kind: 'none',
      label: '非表示',
      language: TEXT_TRACK_LANGUAGE.NONE,
      disable: false,
      set mode(mode) {
        // @ts-ignore TS2339
        const audioLangage = self.state.audioTrack;
        let i = 0;
        _.forEach(noneFnTextTracks, textTrack => {
          if (mode === TEXT_TRACK_MODE.SHOWING) {
            if (textTrack.language === TEXT_TRACK_LANGUAGE.JA && audioLangage === AUDIO_LANGUAGE.JA) {
              textTrack.mode = mode;
            } else if (textTrack.language === TEXT_TRACK_LANGUAGE.EN && audioLangage === AUDIO_LANGUAGE.EN) {
              textTrack.mode = mode;
              // 音声1本の場合は音声の言語情報が取れない。
              // ただしオペミスがない限りこの場合の音声と強制字幕の言語は同じはずなので、0番目を表示・非表示にするようにする
            } else if (i === 0 && audioLangage === AUDIO_LANGUAGE.UND) {
              textTrack.mode = mode;
            } else {
              textTrack.mode = TEXT_TRACK_MODE.DISABLED;
            }
          } else {
            textTrack.mode = TEXT_TRACK_MODE.DISABLED;
          }
          i++;
        });
      },
      get mode() {
        // @ts-ignore TS2339
        const audioLangage = self.state.audioTrack;
        let i = 0;
        const textTrack = _.find(noneFnTextTracks, textTrack => {
          const isJa = textTrack.language === TEXT_TRACK_LANGUAGE.JA && audioLangage === AUDIO_LANGUAGE.JA;
          const isEn = textTrack.language === TEXT_TRACK_LANGUAGE.EN && audioLangage === AUDIO_LANGUAGE.EN;
          const isUnd = i === 0 && audioLangage === AUDIO_LANGUAGE.UND;
          i++;
          return isJa || isEn || isUnd;
        });
        return textTrack ? textTrack.mode : TEXT_TRACK_MODE.DISABLED;
      },
      set default(_default) {
        let i = 0;
        // @ts-ignore TS2339
        const audioLangage = self.state.audioTrack;
        _.forEach(noneFnTextTracks, textTrack => {
          if (_default) {
            if (textTrack.language === TEXT_TRACK_LANGUAGE.JA && audioLangage === AUDIO_LANGUAGE.JA) {
              textTrack.default = _default;
            } else if (textTrack.language === TEXT_TRACK_LANGUAGE.EN && audioLangage === AUDIO_LANGUAGE.EN) {
              textTrack.default = _default;
            } else if (i === 0 && audioLangage === AUDIO_LANGUAGE.UND) {
              textTrack.default = _default;
            } else {
              textTrack.default = false;
            }
          } else {
            textTrack.default = false;
          }
          i++;
        });
      },
      get default() {
        // @ts-ignore TS2339
        const audioLangage = self.state.audioTrack;
        let i = 0;
        const textTrack = _.find(noneFnTextTracks, textTrack => {
          const isJa = textTrack.language === TEXT_TRACK_LANGUAGE.JA && audioLangage === AUDIO_LANGUAGE.JA;
          const isEn = textTrack.language === TEXT_TRACK_LANGUAGE.EN && audioLangage === AUDIO_LANGUAGE.EN;
          const isUnd = i === 0 && audioLangage === AUDIO_LANGUAGE.UND;
          i++;
          return isJa || isEn || isUnd;
        });
        return textTrack ? textTrack.default : false;
      },
    };
  }

  get isPlayStarted() {
    // @ts-ignore TS2339
    return this.state.playStartCompleted && !this.autoPlayErrorFlg;
  }

  initTextTracksSetting() {
    // @ts-ignore TS2339
    if (this.state.initializedTextTracks) return;

    // @ts-ignore TS2339
    const textTrack = _.find(this.enableTextTracks, track => !track.disable && track.language === this.state.textTrack);

    this.setState({ initializedTextTracks: true, initialTextTrack: textTrack ? textTrack.language : null });
  }

  initAudioTracksSetting() {
    // @ts-ignore TS2339
    if (this.state.initializedAudioTracks) return;
    // console.log('WodPlayer:initialAudioTracksSetting');

    const audioTrack = _.find(this.enableAudioTracks, track => {
      // @ts-ignore TS2339
      return !track.disable && track.language == this.state.audioTrack;
    });

    this.setState({
      initializedAudioTracks: true,
      initialAudioTrack: audioTrack ? audioTrack.language : null,
    });
  }

  initAudioAndTextTrack() {
    // console.log('initAudioAndTextTrack');
    // @ts-ignore TS2339
    if (this.state.initializedAudioAndTextTracks) return;
    this.setState({ initializedAudioAndTextTracks: true });

    // @ts-ignore TS2339
    let initialAudioTrack = this.state.initialAudioTrack;
    if (this.enableAudioTracks.length == 1 && initialAudioTrack === null) {
      // 字幕がある場合(非表示も含むのでこの条件)で音声一本の場合は英語音声とする
      if (this.enableTextTracks.length > 1) {
        initialAudioTrack = AUDIO_LANGUAGE.EN;
      } else {
        initialAudioTrack = AUDIO_LANGUAGE.JA;
      }
    }
    // 音声字幕のどちらかでもローカルの設定と異なる場合はデフォルト
    // @ts-ignore TS2339
    if (this.state.audioTrack !== initialAudioTrack || this.state.textTrack !== this.state.initialTextTrack) {
      // 音声
      // いじらずstateだけ更新する
      const audioTrack = _.find(this.enableAudioTracks, track => track.enabled);
      this.setState({ audioTrack: audioTrack ? audioTrack.language : AUDIO_LANGUAGE.UND }, () => {
        // 字幕
        // stateの音声を見て強制字幕を出しているのでsetstateのcallback
        const defaultTextTrack = _.find(this.enableTextTracks, track => track.default);
        const textTrackLang = defaultTextTrack ? defaultTextTrack.language : TEXT_TRACK_LANGUAGE.NONE;
        _.forEach(this.enableTextTracks, track => {
          if (track.language === textTrackLang) {
            track.mode = TEXT_TRACK_MODE.SHOWING;
            this.setState({ textTrack: track.language });
          } else {
            track.mode = TEXT_TRACK_MODE.DISABLED;
            track.default = false;
          }
        });
      });
    } else {
      // 音声
      // 1本であればそれが選択されるので不要
      if (this.enableAudioTracks.length > 1) {
        _.forEach(this.enableAudioTracks, track => {
          // @ts-ignore TS2339
          track.enabled = track.language === this.state.initialAudioTrack;
        });
      }
      // 字幕
      _.forEach(this.enableTextTracks, track => {
        // @ts-ignore TS2339
        if (track.language === this.state.textTrack) {
          track.mode = TEXT_TRACK_MODE.SHOWING;
        } else {
          track.default = false;
          track.mode = TEXT_TRACK_MODE.DISABLED;
        }
      });
    }
  }

  onPlayerStateChange(state) {
    this.setState(state);
  }

  onPlayerLoadedmetadata() {
    // console.log('Player: onPlayerLoadedmetadata');

    this.setState({ metadataloaded: true });

    // このタイミング以前に変更すると再生速度が変わらない為ここで処理する
    // if (this.props.playbackRate) {
    //   this._player.playbackRate(this.props.playbackRate);
    // }
    // @ts-ignore TS2339
    if (this.state.playbackRate) {
      // @ts-ignore TS2339
      this._player.playbackRate(this.state.playbackRate);
    }

    // if (!this.props.autoplay) {
    // @ts-ignore TS2339
    // this._player.pause();
    // this.autoPlayError();
    // }
  }

  onPlayerLoadeddata() {
    // console.log('Player: onPlayerLoadeddata');

    this.initAudioTracksSetting();
    this.setState({ loaded: true });
  }

  onPlayerLoaded() {
    // console.log('Player: onPlayerLoaded');
    // @ts-ignore TS2339
    if (this.__gCastCurrentTime) {
      // @ts-ignore TS2339
      this._player.currentTime(this.__gCastCurrentTime);
      // @ts-ignore TS2339
      this.__gCastCurrentTime = null;
      return;
    }

    this.setState({ loaded: true });
    // @ts-ignore TS2339
    if (this.props.onPlayerLoaded) {
      // @ts-ignore TS2339
      this.props.onPlayerLoaded();
    }

    // ボリュームの指定
    // @ts-ignore TS2339
    this._player.volume(this.state.muted ? 0 : this.state.volume);
  }

  onPlayerReady(obj) {
    // console.log('onPlayerReady');
    this.setState({ ready: true });
    // @ts-ignore TS2339
    if (this.props.onPlayerReady) {
      // @ts-ignore TS2339
      this.props.onPlayerReady(this._player);
    }
  }

  onPlayerIdle() {
    //console.log('onPlayerIdle');
    // console.log('WodPlayer paused true')
    this.setState({ paused: true, rebuffering: false, playStartCompleted: false, gCastLoading: false });
  }

  onPlayerPlay() {
    // console.log('onPlayerPlay');
    // @ts-ignore TS2339
    if (this.state.isAdMode) {
      // @ts-ignore TS2339
      if (this.activeTimeoutId) {
        // @ts-ignore TS2339
        clearTimeout(this.activeTimeoutId);
        // @ts-ignore TS2339
        delete this.activeTimeoutId;
      }
      this.setState({ uiState: 'active' });
      // @ts-ignore TS2339
      this.activeTimeoutId = setTimeout(() => {
        this.setState({ uiState: 'inactive' });
      }, 3000);
    }
    // console.log('WodPlayer paused false')
    // @ts-ignore TS2551
    if (this.autoPlayErrorFlg) this.autoPlayErrorFlg = false;
    this.setState({ paused: false, ended: false, gCastLoading: false, rebuffering: false }, () => {
      const playbackService = Object.assign({}, this.context.getModelData('services', 'playback'));
      const authContext = this.context.getModelData('authContext');
      // @ts-ignore TS2339
      const playbackSessionId = this._player.getPlaybackSessionId(
        _.get(this.state, 'activeVideo.session.id'),
        _.get(this.state, 'activeVideo.analytics', {}),
        // @ts-ignore TS2339
        this.state.playContext,
      );
      // @ts-ignore TS2339
      this._player.checkVideo(playbackService, authContext, playbackSessionId, (res, status: number) => {
        // @ts-ignore TS2339
        if (!this._isMounted) return;
        if (res.result === true) return;
        if (res.error === 'invalid_grant' && status === 400) {
          // @ts-ignore TS2339
          if (this.props.reflash && this.alreadyEnded) {
            // @ts-ignore TS2339
            this.props.reflash();
          } else {
            // @ts-ignore TS2339
            if (this.props.watchPartyMode && !this.context.watchPartyApp.isMaster()) {
              // WP参加者の場合、Watch.jsのonPlayerReadyでリクエストURLから[?wp=<roomId>]を取ったので
              // ここで再生できない時に元リクエストURLを戻して、WP参加リンクからリロードする必要
              // @ts-ignore TS2339
              let roomUrl = this.context.watchPartyApp.getRoomUrl(this.context, this.state.metadata);
              window.location.href = roomUrl;
            }
            window.location.reload();
          }
        }
      });
    });
    // @ts-ignore TS2339
    if (this.props.onPlayerPlay) {
      // @ts-ignore TS2339
      this.props.onPlayerPlay();
    }
    // @ts-ignore TS2339
    this.context.watchPartyApp.postCommand('play', { currentTime: this._player.currentTime() }, (error, body) => {
      if (error) {
        console.log(error, body);
      }
    });
  }

  onPlayerPause() {
    //console.log('onPlayerPause', this.state.paused);
    // console.log('WodPlayer paused true')
    this.setState({ paused: true, rebuffering: false });
    // @ts-ignore TS2339
    if (this.props.onPlayerPause) {
      // @ts-ignore TS2339
      this.props.onPlayerPause();
    }
    this.stopWaitingCurrentTimeCheck();
    // @ts-ignore TS2339
    if (this.playAnalytics.timerId) {
      // @ts-ignore TS2339
      clearInterval(this.playAnalytics.timerId);
      // @ts-ignore TS2339
      delete this.playAnalytics.timerId;
    }
    // @ts-ignore TS2339
    this.context.watchPartyApp.postCommand('pause', { currentTime: this._player.currentTime() }, (error, body) => {
      if (error) {
        console.log(error, body);
      }
    });
  }

  onPlayerLoadstart() {
    // console.log('onPlayerLoadstart');

    this.setState({ loadstart: true });

    // @ts-ignore TS2339
    if (this.props.onPlayerLoadstart) {
      // @ts-ignore TS2339
      this.props.onPlayerLoadstart();
    }
  }

  onPlayerPlayStartCompleted() {
    //console.log('onPlayerPlayStartCompleted');
    this.setState({ playStartCompleted: true });
  }

  onPlayerPlaying() {
    // console.log('onPlayerPlaying');
    if (!this._isMounted) return;
    this.disableVideoTagTextTrack();

    // console.log('WodPlayer paused false')
    const newState = {
      paused: false,
      loading: false,
      rebuffering: false,
      stalled: false,
      emptied: false,
    };
    // @ts-ignore TS2339
    if (this.state.error) {
      // @ts-ignore TS2339
      newState.error = null;
    }
    // @ts-ignore TS2339
    if (this.state.errorView) {
      // @ts-ignore TS2339
      newState.errorView = null;
    }
    // @ts-ignore TS2551
    if (this.autoPlayErrorFlg) this.autoPlayErrorFlg = false;
    this.setState(newState, () => {
      // @ts-ignore TS2339
      if (this.props.onPlayerPlaying) {
        // @ts-ignore TS2339
        this.props.onPlayerPlaying();
      }
    });

    // 広告再生時は飛ばさない
    // @ts-ignore TS2339
    if (!this.state.willPlayAd && !this.state.isAdMode) {
      // @ts-ignore TS2339
      if (this.props.onSendToGtm) {
        // @ts-ignore TS2339
        this.props.onSendToGtm(CONTENT_EVENTS.PLAY_START);
      }
    }

    // @ts-ignore TS2339
    if (!this.playAnalytics.sentOneMin) {
      const accumlate = () => {
        // @ts-ignore TS2339
        this.playAnalytics.accumPlayingSec++;
      };
      // @ts-ignore TS2339
      this.playAnalytics = {
        startDate: new Date().getTime(),
        // @ts-ignore TS2339
        playerFirstPlayTime: this.playAnalytics.playerFirstPlayTime || this._player.currentTime(),
        sentOneMin: false,
        sentFiftyPercent: false,
        sentNinetyPercent: false,
        // @ts-ignore TS2339
        accumPlayingSec: this.playAnalytics.accumPlayingSec || 0,
        // @ts-ignore TS2339
        timerId: this.playAnalytics.timerId || setInterval(accumlate.bind(this), 1000),
      };
    }
  }

  onPlayerAdsAdStarted() {
    // console.log('onPlayerAdsAdStarted');
    const adDuration = this._player
      .localPlayer()
      .currentAd()
      .getDuration();
    this.setState({ paused: false, adDuration: adDuration });
  }

  onPlayerAdsAdEnded() {
    // console.log('onPlayerAdsAdEnded');
  }

  onPlayerAdModeStart() {
    // console.log('onPlayerAdModeStart');
    this.setState({ isAdMode: true });
  }

  onPlayerAdModeEnd() {
    // console.log('onPlayerAdModeEnd');
    this.setState({ isAdMode: false });
  }

  onPlayerAdBreakStart() {
    // console.log('onPlayerAdBreakStart');
    this.onPlayerPlay();
  }

  onPlayerAdBreakEnd() {
    // console.log('onPlayerAdBreakEnd');
    // if (this.state.isAdMode) {
    //   this.setState({ isAdMode: false });
    // }
  }

  onPlayerAdsPlay() {
    // console.log('onPlayerAdsPlay');
    this.onPlayerPlay();
  }

  onPlayerAdsPause() {
    // console.log('onPlayerAdsPause');
    this.onPlayerPause();
  }

  setWillPlayAd(willPlayAd: boolean) {
    // @ts-ignore TS2339
    if (willPlayAd !== this.state.willPlayAd) {
      this.setState({ willPlayAd });
    }
  }

  onPlayerAdsAdTimeUpdate() {
    const currentAdTime = this._player.localPlayer().currentAdTime();
    this.setState({ currentAdTime: currentAdTime });
  }

  onPlayerFirstplay(isPlayerEvent: boolean = true) {
    // console.log('onPlayerFirstplay');
    // @ts-ignore TS2339
    if (!this.state.loading) {
      this.setState({ playStartCompleted: true, gCastLoading: false });
      // @ts-ignore TS2339
      if (this.props.onPlayerFirstplay) {
        // @ts-ignore TS2339
        this.props.onPlayerFirstplay();
      }
    }
  }

  onPlayerRebuffering() {
    this.setState({ rebuffering: true, canplaythrough: false });
  }

  onPlayerTimeUpdate(currentTime) {
    // safariフルスクリーンの再生終了時にended後にtimeupdateが発火するためその場合を考慮
    // @ts-ignore TS2339
    if (this.state.ended) return;

    // console.log('onPlayerTimeUpdate', currentTime);
    // @ts-ignore TS2339
    this.__currentTime = currentTime;

    // waitingでrebufferingをtrueにしてSpinnerを表示しているが
    // edgeだとfalseにするイベントが発生しないのでこのタイミングでフラグを落とす
    // @ts-ignore TS2551
    if (this.__waitingCurrentTime && !this.waitingTimeoutId) {
      //console.log('onPlayerTimeUpdate', this.__waitingCurrentTime, this._player.currentTime(), this.state.rebuffering, this.state.paused);
      // 一瞬で呼ばれて時間が変わらない場合があるので一定期間チェックする
      this.startWaitingCurrentTimeCheck();
    }

    // 6秒間は表示させる
    if (
      // @ts-ignore TS2339
      this.props.videoSkip &&
      // @ts-ignore TS2339
      this.firstSkipFlg &&
      // @ts-ignore TS2339
      !this.state.autoHideVideoSkip &&
      // @ts-ignore TS2339
      this.props.videoSkip.start + 6 < currentTime
    ) {
      // 但し、再生開始とバッティングした時は余裕を持たせる
      // @ts-ignore TS2339
      if (this.__playStartCompletedTime && Date.now() - this.__playStartCompletedTime > 6 * 1000) {
        this.setState({ autoHideVideoSkip: true });
      }
    }

    // Liveに追いついた時は再生速度を戻す
    // @ts-ignore TS2339
    let duration = this._player.duration();
    // @ts-ignore TS2339
    if (this._player.playbackRate() !== 1 && duration === Infinity) {
      try {
        // @ts-ignore TS2339
        let seekableEnd = this._player.seekable().end(0);
        seekableEnd = seekableEnd - 6; // 1セグメント分
        if (seekableEnd - currentTime <= 0) {
          // 再生速度が1でない場合は1にする
          // @ts-ignore TS2339
          this._player.playbackRate(1);
        }
      } catch (e) {}
    }

    // @ts-ignore TS2339
    if (this.props.onPlayerTimeUpdate) {
      // @ts-ignore TS2339
      this.props.onPlayerTimeUpdate(currentTime);
    }

    // @ts-ignore TS2339
    if (!_.get(this.playAnalytics, 'sentOneMin', true)) {
      const MIN_WATCH_INTERVAL = 60;
      // @ts-ignore TS2339
      const playerCurrentTimeDiff = currentTime - this.playAnalytics.playerFirstPlayTime;
      // @ts-ignore TS2339
      if (this.playAnalytics.accumPlayingSec > MIN_WATCH_INTERVAL && playerCurrentTimeDiff > MIN_WATCH_INTERVAL) {
        // @ts-ignore TS2339
        this.playAnalytics.sentOneMin = true;
        // @ts-ignore TS2339
        clearInterval(this.playAnalytics.timerId);
        // @ts-ignore TS2339
        delete this.playAnalytics.timerId;
        // @ts-ignore TS2339
        if (this.props.onSendToGtm) {
          // @ts-ignore TS2339
          this.props.onSendToGtm(CONTENT_EVENTS.PLAY_1MIN);
        }
      }
    }
    if (duration && duration !== Infinity) {
      // @ts-ignore TS2339
      if (!_.get(this.playAnalytics, 'sentFiftyPercent', true)) {
        const roundedCurTime = Math.round(currentTime);
        const fiftyPercent = Math.round(duration * 0.5);
        if (roundedCurTime === fiftyPercent) {
          // @ts-ignore TS2339
          this.props.onSendToGtm(CONTENT_EVENTS.PLAY_50PERCENT);
          // @ts-ignore TS2339
          this.playAnalytics.sentFiftyPercent = true;
        }
      }
      // @ts-ignore TS2339
      if (!_.get(this.playAnalytics, 'sentNinetyPercent', true)) {
        const roundedCurTime = Math.round(currentTime);
        const ninetyPercent = Math.round(duration * 0.9);
        if (roundedCurTime === ninetyPercent) {
          // @ts-ignore TS2339
          this.props.onSendToGtm(CONTENT_EVENTS.PLAY_90PERCENT);
          // @ts-ignore TS2339
          this.playAnalytics.sentNinetyPercent = true;
        }
      }
    }
  }

  onPlayerKeyDown() {
    this.setState({ uiState: 'active', playStartCompleted: true });
  }

  onPlayerSrcFallback() {
    // console.log('WodPlayer: onPlayerSrcFallback');
    // this.srcFallback = true;
    // if (this.props.onFallbackModal) this.props.onFallbackModal();
  }

  onPlayerOutputRestricted() {
    // console.log('WodPlayer: onPlayerOutputRestricted');
    // @ts-ignore TS2339
    if (this.props.onFallbackModal) this.props.onFallbackModal();
  }

  onPlayerTextTracksChange() {
    // console.log('WodPlayer: onPlayerTextTracksChange');

    // 字幕読み込み前に上書きしないようにする
    // @ts-ignore TS2339
    if (!this.state.initializedTextTracks) return;

    // @ts-ignore TS2339
    const textTracks = this._player.textTracks();

    if ((textTracks || []).length > 0) {
      const textTrack =
        _.get(
          _.find(textTracks, textTrack => textTrack.mode === 'showing'),
          'language',
        ) || 'none';
      this.setState({ textTrack }, () => {
        // @ts-ignore TS2339
        if (this.props.onPlayerTextTracksChange) {
          // @ts-ignore TS2339
          this.props.onPlayerTextTracksChange(textTrack);
        }
      });
    }
  }

  onPlayerAudioTracksChange() {
    // console.log('WodPlayer: onPlayerAudioTracksChange');

    // 音声読み込み前に上書きしないようにする
    // @ts-ignore TS2339
    if (!this.state.initializedAudioTracks) return;

    // @ts-ignore TS2339
    const audioTracks = this._player.audioTracks();

    // 複数ある場合のみ引き継ぎするようにする
    if ((audioTracks || []).length > 1) {
      const audioTrack =
        _.get(
          _.find(audioTracks, audioTrack => audioTrack.enabled === true),
          'language',
        ) || AUDIO_LANGUAGE.UND;

      this.setState({ audioTrack }, () => {
        // @ts-ignore TS2339
        if (this.state.textTrack === TEXT_TRACK_LANGUAGE.NONE) {
          // 音声によって強制字幕の種類が変わるのでここでshowingを呼ぶ
          this.noneTextTrack.mode = TEXT_TRACK_MODE.SHOWING;
        }
        // @ts-ignore TS2339
        if (this.props.onPlayerAudioTracksChange) {
          // @ts-ignore TS2339
          this.props.onPlayerAudioTracksChange(audioTrack);
        }
      });
    }
  }

  onPlayerEnterPictureInPicture() {
    // console.log('WodPlayer: onPlayerEnterPictureInPicture', this._player.isInPictureInPicture());
    // @ts-ignore TS2339
    this.setState({ isInPictureInPicture: this._player.isInPictureInPicture() });
  }

  onPlayerLeavePictureInPicture() {
    // console.log('WodPlayer: onPlayerLeavePictureInPicture', this._player.isInPictureInPicture());
    // @ts-ignore TS2339
    this.setState({ isInPictureInPicture: this._player.isInPictureInPicture() });
    // シークできない時又はリニアの場合は再生させる
    // @ts-ignore TS2339
    if (!this.enableSeek) {
      // @ts-ignore TS2339
      this._player.play();
    }
  }

  togglePictureInPicture() {
    // console.log('WodPlayer: togglePictureInPicture', this._player.isInPictureInPicture());
    // @ts-ignore TS2339
    if (this._player.isInPictureInPicture()) {
      // @ts-ignore TS2339
      this._player.exitPictureInPicture();
    } else {
      // @ts-ignore TS2339
      this._player.requestPictureInPicture();
    }
  }

  onPlayerVolumeChange(volume) {
    //console.log('onPlayerVolumeChange', volume);

    // iOSの場合、ボリュームの変更はできない必ず1になる（ミュートした時も）
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    if (this._player.localPlayer() && browserInfo.isIOS) {
      // @ts-ignore TS2339
      this.setState({ muted: this._player.localPlayer().muted() });
      return;
    }

    // ミュートでない時に記録する ミュートした時に0になるから
    const volumeData = volumeDataStore.get() || {};
    // @ts-ignore TS2339
    if (!this.state.muted || volume) {
      volumeData.volume = Math.max(0.05, volume);
    }
    // @ts-ignore TS2339
    volumeData.muted = this.state.muted;
    volumeDataStore.set(volumeData);

    const newState = {
      muted: volume == 0,
    };
    if (volume != 0) {
      // @ts-ignore TS2339
      newState.volume = volume;
    }
    this.setState(newState);
  }

  onPlayerMuted(muted) {
    this.setState({ muted });
  }

  onPlayerStalled() {
    this.setState({ stalled: true, canplaythrough: false });
  }

  onPlayerEmptied() {
    this.setState({ emptied: true, canplaythrough: false });
  }

  onPlayerSuspend() {
    this.setState({ emptied: true, canplaythrough: false }, () => {
      // @ts-ignore TS2339
      if (this.props.onSuspend) {
        // @ts-ignore TS2339
        this.props.onSuspend();
      }
    });
  }

  onPlayerWaiting() {
    //console.log('onPlayerWaiting');
    this.stopWaitingCurrentTimeCheck();
    // @ts-ignore TS2551
    this.__waitingCurrentTime = this._player.currentTime();
    this.setState({ rebuffering: true, canplaythrough: false });
  }

  onPlayerCanplay() {
    // console.log('onPlayerCanplay');

    this.initAudioTracksSetting();

    this.setState({ rebuffering: false });
  }

  onPlayerSeeked() {
    this.setState({ seeking: false, rebuffering: false }, () => {
      // @ts-ignore TS2339
      if (this._player.playReload) {
        // @ts-ignore TS2339
        if (this._player.currentTime() == 0) {
          // @ts-ignore TS2339
          this._player.playReload = false;
        }
        setTimeout(() => {
          // console.log('WodPlayer: play 1')
          // @ts-ignore TS2339
          this._player.play();
        }, 100);
        // @ts-ignore TS2339
      } else if (!this.state.playStartCompleted && !this.state.paused) {
        const browserInfo = this.context.getModelData('browserInfo');
        if (!browserInfo.isSafari || parseInt(browserInfo.major, 10) <= 10) {
          // console.log('WodPlayer: play 2')
          // @ts-ignore TS2339
          this._player.play();
        }
      }
    });
  }

  onPlayerSeeking() {
    this.setState({ seeking: true, ended: false });
    // @ts-ignore TS2339
    this.context.watchPartyApp.postCommand('seek', { currentTime: this._player.currentTime() }, (error, body) => {
      if (error) {
        console.log(error, body);
      }
    });
  }

  onPlayerCanplaythrough() {
    // console.log('onPlayerCanplaythrough');
    this.disableVideoTagTextTrack();
    this.setState({ canplaythrough: true });
  }

  onPlayerDurationChange(duration) {
    // @ts-ignore TS2339
    this.__duration = duration;
    // @ts-ignore TS2339
    if (this.props.onPlayerDurationChange) {
      // @ts-ignore TS2339
      this.props.onPlayerDurationChange(duration);
    }
  }

  onPlayerPlaybackRateChange(playbackRate) {
    // 速度変更可能な場合のみ設定する
    // @ts-ignore TS2339
    if (this.state.playContext && this.state.playContext.get('playbackRule').enablePlaybackRateChange !== false) {
      const settingsData = settingsDataStore.get() || {};
      settingsData.playbackRate = playbackRate;
      settingsDataStore.set(settingsData);

      this.setState({ playbackRate }, () => {
        // @ts-ignore TS2339
        if (this.props.onPlayerPlaybackRateChange) {
          // @ts-ignore TS2339
          this.props.onPlayerPlaybackRateChange(playbackRate);
        }
      });
    }
  }

  onPlayerEnded() {
    this.setState({ ended: true, rebuffering: false });
    // @ts-ignore TS2339
    if (this.props.onPlayerEnded) {
      // @ts-ignore TS2339
      this.props.onPlayerEnded();
    }
    this.stopWaitingCurrentTimeCheck();
  }

  onPlayerError(error) {
    if (!this._isMounted) return;

    let newState;
    if (typeof error === 'object' && error.constructor.name === 'Jb') {
      newState = { error: _.assign(error, { code: error.dcode }) };
    }
    if (_.startsWith(_.get(error, 'dcode'), 'S-')) {
      newState = { error: _.assign(error, { code: error.dcode }) };
    }

    // @ts-ignore TS2339
    if (this.props.onPlayerError) {
      // @ts-ignore TS2339
      this.props.onPlayerError(error);
    }

    this.setState(newState);
    this.stopWaitingCurrentTimeCheck();
  }

  focusOnPlayer() {
    // @ts-ignore TS2339
    this.playerElement.focus();
  }

  /**
   * 認証を行なって動画再生に必要な情報を取得する
   * TODO: この処理はBluerosePlayer.jsにも記載されているが、BluerosePlayerからこちらに完全に移行させる必要がある
   */
  prepareToPlay(props = {}, context = {}, retryCount = 0) {
    // @ts-ignore TS2339
    if (!props.id && !props.mediaId) return;

    this.setState({ prepareLoading: true });
    let canceled = false;
    // @ts-ignore TS2339
    this.__$promise = new Promise((resolve, reject) => {
      const browserInfo = this.context.getModelData('browserInfo');
      if (!browserInfo.features.playable) {
        const newState = {
          playContext: playContext(context)
            .set('video', {})
            .set('playbackRule', {})
            .set('session', {}),
          fetching: false,
        };
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
        return;
      }

      let useCheckAvailability = false;

      // スマホの場合
      if (browserInfo.isAndroid || browserInfo.isIOS || browserInfo.isRequestDesktopWebsite) {
        useCheckAvailability = true;
      }

      if (useCheckAvailability) {
        const q = {
          // @ts-ignore TS2339
          meta_id: props.id,
          // @ts-ignore TS2339
          media_id: props.mediaId,
          error_flag: true,
          _strict: 1,
        };
        this.playbackUsecase
          .checkAvailability(q)
          .then(result => {
            const newState = {
              playContext: playContext(context)
                .set('video', {})
                .set('playbackRule', {})
                .set('session', {}),
              fetching: false,
            };
            if (this._isMounted) this.setState(newState);
            else this.state = Object.assign(newState);
          })
          .catch(e => {
            return reject(e);
          });
        return;
      }

      let vuid = VUID;

      // vuidキャッシュがあるときはキャッシュを返す
      if (!vuid) {
        vuid = vuidDataStore.get();
      }

      // vuidないときはキャッシュする
      if (!vuid) {
        vuid = vuidDataStore.create();
        vuidDataStore.set(vuid);
      }

      if (vuid) {
        VUID = vuid;
      }

      if (!DSID) {
        DSID = this.context.cookies.get(DSID_KEY);
        if (!DSID) {
          DSID = generateUuid(false);
        }
        if (window.navigator.cookieEnabled) {
          this.context.cookies.set(DSID_KEY, DSID, { path: '/' });
        }
      }

      // TODO 設定は別で持つ
      const q = {
        // @ts-ignore TS2339
        meta_id: props.id,
        // @ts-ignore TS2339
        media_id: props.mediaId,
        vuid,
        // @ts-ignore TS2339
        device_code: context.getModelData('browserInfo', 'deviceCode'),
        app_id: 1,
        ua: window.navigator.userAgent,
      };
      // @ts-ignore TS2554
      const profile = activeProfile(context.models);
      if (profile && profile.id) {
        // @ts-ignore TS2339
        q.user_id = profile.id;
      }
      this.playbackUsecase
        .auth(q)
        .then(result => {
          resolve(result.data);
        })
        .catch(e => reject(e));
    })
      .then(mediaInfo => {
        if (canceled) return;

        // @ts-ignore TS2339
        const browserInfo = context.getModelData('browserInfo');
        // @ts-ignore TS2554
        const profile = activeProfile(context.models);

        // beacon
        const analytics = {
          vuid: VUID,
          dsid: DSID,
          t_flg: browserInfo.isBMW ? '15' : browserInfo.isAudi ? '16' : '03', // 端末種別
          os_version: _.get(browserInfo, 'os.version'), // OSバージョン
          download_flag: 0, // WEBは常に0
        };
        if (profile) {
          // @ts-ignore TS2339
          analytics.member_id = profile.refId; // WOLメンバーID
          // @ts-ignore TS2339
          analytics.usernum = profile.customerNum || ''; // お客様番号(テレビ会員番号customer_num)
          // @ts-ignore TS2339
          analytics.k_type = profile.kType || ''; // 連携元フラグ
        }

        const video = {
          seekPreview: {
            urls: _.get(mediaInfo, 'media.thumbnail_tile_urls', []),
          },
          videoId: _.get(mediaInfo, 'media.ovp_video_id'),
          ovpId: _.get(mediaInfo, 'media.ovp_id'),
          // @ts-ignore TS2339
          analytics: Object.assign({}, mediaInfo.log_params, analytics),
          session: {
            // @ts-ignore TS2339
            id: mediaInfo.playback_session_id,
          },
          logo: {},
          progressImageRoot: null,
          // @ts-ignore TS2339
          metadata: this.state.metadata,
          requiresAdultVerification: false,
          requiresPin: false,
          requiresPreReleasePin: false,
          hd: true,
          cretisOffset: 0,
          autoplayable: true,
          artwork: [],
          mediaValues: {
            videoSkips: _.map(_.get(mediaInfo, 'media.values.video_skips'), (position, i) => {
              return {
                idx: i,
                // @ts-ignore TS2339
                start: position.start_position + 2,
                // @ts-ignore TS2339
                end: position.end_position - 2,
              };
            }),
            openingEndPosition: _.get(mediaInfo, 'media.values.opening_end_position'),
            endingStartPosition: _.get(mediaInfo, 'media.values.ending_start_position'),
          },
        };

        const newPlayContext = playContext(context)
          .set('video', video)
          // @ts-ignore TS2339
          .set('playbackRule', playbackRule(mediaInfo.playback_rule))
          .set('session', {
            // @ts-ignore TS2339
            id: mediaInfo.playback_session_id,
          });

        const newState = {
          playContext: newPlayContext,
          activeVideo: newPlayContext.get('video'),
          videoId: _.get(mediaInfo, 'media.ovp_video_id'),
          prepareLoading: false,
          fetching: false,
        };
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      })
      .catch(e => {
        if (canceled) return;

        let promise;
        if (e === ERROR.LICENSE_INVALID) {
          promise = new Promise((resolve, reject) => {
            // TODO: ログインが必要かどうかの判定はhowto_playを叩く前に判定する
            // ログインしている場合は最初からauthを叩き視聴できない時にhowto_playを叩く順番とする
            // @ts-ignore TS2339
            this._axios
              // @ts-ignore TS2339
              .get(`/api/meta/${this.props.id}/howto_play`)
              .then(result => {
                if (canceled) return;

                // @ts-ignore TS2339
                const authContext = context.getModelData('authContext');
                // @ts-ignore TS2554
                const profile = activeProfile(context.models);
                const howToPlayInfo = result.data;

                if (!howToPlayInfo.free || howToPlayInfo.memberOnly) {
                  // ログインが必要だけどログインしていなかったらログインダイアログ出す
                  if (!authContext) {
                    return reject(ERROR.UNAUTHORIZED);

                    // メタに視聴権利が設定されていなかった場合はエラーを出す
                  } else if (_.get(howToPlayInfo, 'rights.length') === 0) {
                    return reject(ERROR.META_NOT_VIEWABLE);
                  }
                }
                return resolve(howToPlayInfo);
              })
              .catch(reject);
          });
        } else {
          promise = Promise.reject(e);
        }

        promise
          .then(howToPlayInfo => {
            throw { code: ERROR.LICENSE_INVALID, object: howToPlayInfo };
          })
          .catch(e => {
            if (canceled) return;

            if (retryCount <= 2 && e.type !== ERROR.LICENSE_INVALID && e.type !== ERROR.UNAUTHORIZED) {
              if (_.get(e, 'error.code') !== 2020) {
                retryCount = retryCount + 1;
                setTimeout(() => {
                  if (canceled) return;
                  this.prepareToPlay(props, context, retryCount);
                }, retryCount * 2000);
                return;
              }
            }
            const newState = {
              error: e,
              fetching: false,
            };
            if (this._isMounted) this.setState(newState);
            else this.state = Object.assign(newState);
          });
      });
    // @ts-ignore TS2339
    this.__$promise.dispose = function() {
      canceled = true;
    };
  }

  dispose() {
    //console.log('Player: dispose');
    // @ts-ignore TS2339
    if (!this.state.ended && this.state.playStartCompleted) {
      this.addResume();
    }
    this.stopWaitingCurrentTimeCheck();
  }

  getFullScreenNode() {
    // @ts-ignore TS2339
    if (this.props.getFullScreenNode) {
      // エピソード詳細
      // @ts-ignore TS2339
      return this.props.getFullScreenNode();
    } else {
      // お試し再生
      return ReactDOM.findDOMNode(this);
    }
  }

  beforeFullScreenChange(isFullScreen) {
    // console.log('beforeFullScreen', isFullScreen)
    if (isFullScreen) {
      this.beforeEnterFullScreen();
    } else {
      this.beforeExitFullScreen();
    }
  }

  beforeEnterFullScreen() {
    // ベゼル表示→フルスクリーンの順でアニメーションしたい
    this.triggerActionState('fullscreen');
  }

  beforeExitFullScreen() {
    // ベゼル表示→フルスクリーンの順でアニメーションしたい
    this.triggerActionState('window');
  }

  onFullScreenChange(isFullScreen) {
    if (isFullScreen) {
      this.onEnterFullScreen();
    } else {
      this.onExitFullScreen();
    }
    // console.log(isFullScreen);
    this.setState({ isFullScreen });
  }

  onEnterFullScreen() {
    // フルスクリーン時はPIPをやめる
    // @ts-ignore TS2339
    if (this._player.isInPictureInPicture()) {
      // @ts-ignore TS2339
      this._player.exitPictureInPicture();
    }
  }

  onExitFullScreen() {}

  toggleFullScreen() {
    // @ts-ignore TS2339
    this._player.toggleFullScreen();
  }

  setKeepActive(keepActive) {
    // @ts-ignore TS2339
    this.__keepActive = keepActive;
    // @ts-ignore TS2339
    if (this.activeTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.activeTimeoutId);
      // @ts-ignore TS2339
      delete this.activeTimeoutId;
    }
    if (keepActive) {
      this.setState({ uiState: 'active' });
    } else {
      // @ts-ignore TS2339
      this.activeTimeoutId = setTimeout(() => {
        this.setState({ uiState: 'inactive' });
      }, 3000);
    }
  }

  onChangeQuality(quality) {
    const settingsData = settingsDataStore.get() || {};
    settingsData.quality = quality;
    settingsDataStore.set(settingsData);

    // @ts-ignore TS2339
    delete this.state.minBitrate;
    // @ts-ignore TS2339
    delete this.state.maxBitrate;
    this.setState({ quality });
  }

  onClickPlay() {
    this.setHandleSeekerBarMode(false);
    // console.log('Player: onClickPlay');
    // キャスト中にSPAで遷移した時
    // @ts-ignore TS2339
    if (this._player.isGCasted() && !this.state.playStartCompleted) {
      // @ts-ignore TS2339
      this._player.clickGCastButton();
      this.gCastStart();
      return;
    }

    // @ts-ignore TS2339
    if (this.state.paused) {
      // console.log('WodPlayer: play 3')
      this.triggerActionState('play');
      // @ts-ignore TS2339
      this._player.play();
      // @ts-ignore TS2339
    } else if (typeof this._player.paused === 'function' && this._player.paused() && !this.state.isAdMode) {
      // console.log('WodPlayer: play 4')
      this.triggerActionState('play');
      // @ts-ignore TS2339
      this._player.play();
      // @ts-ignore TS2339
    } else if (this.enablePause) {
      // console.log('WodPlayer: pause 1')
      this.triggerActionState('pause');
      // @ts-ignore TS2339
      this._player.pause();
    }
  }

  onClickReload() {
    // 認証し直す必要がある
    // @ts-ignore TS2339
    if (this.props.onClickReload) {
      // @ts-ignore TS2339
      this.props.onClickReload();
    } else {
      // @ts-ignore TS2339
      delete this.__currentTime;
      // @ts-ignore TS2339
      this._player.reload();
    }
  }

  onClickVolume() {
    //console.log('this.state.muted', this.state.muted);
    // @ts-ignore TS2339
    if (this.state.muted) {
      // @ts-ignore TS2339
      this.triggerActionState(this.getVolumeActionName(this.state.volume));
      this.setState({ muted: false }, () => {
        // @ts-ignore TS2339
        this._player.muted(false);
      });
    } else {
      this.triggerActionState('mute');
      this.setState({ muted: true }, () => {
        // @ts-ignore TS2339
        this._player.muted(true);
      });
    }
  }

  onClickCastButton(e) {
    // @ts-ignore TS2339
    this._player.clickGCastButton();
  }

  onClickSeekBack(e) {
    this.triggerActionState('replay');
    if (this.context.playerApp) {
      // @ts-ignore TS2339
      this._player.trackEvent('skipback');
      // @ts-ignore TS2339
    } else if (this.playerRef.current) {
      // @ts-ignore TS2339
      this.playerRef.current.trackEvent('skipback');
    }
  }

  onClickSeekForward(e) {
    this.triggerActionState('forward');
    if (this.context.playerApp) {
      // @ts-ignore TS2339
      this._player.trackEvent('skipforward');
      // @ts-ignore TS2339
    } else if (this.playerRef.current) {
      // @ts-ignore TS2339
      this.playerRef.current.trackEvent('skipforward');
    }
  }

  onClickVideoSkip(e) {
    // @ts-ignore TS2339
    if (!this.state.ended) {
      // @ts-ignore TS2339
      let currentTime = this.props.videoSkip.end;
      // @ts-ignore TS2339
      this._player.currentTime(currentTime);
      this.setKeepActive(false);
    }
  }

  onKeydownVideoSkip(e) {
    if (e.keyCode == 13) {
      // @ts-ignore TS2554
      this.onClickVideoSkip();
    }
  }

  handleHoverControls(hoverControls) {
    this.setState({ hoverControls });
  }

  isEnding() {
    // ライブはfalse
    // @ts-ignore TS2339
    if (this._player.duration() && this._player.duration() === Infinity) {
      return false;
    }
    // @ts-ignore TS2339
    const episodeRuntime = this._player.duration() || _.get(this.state.metadata, 'episodeRuntime');
    // @ts-ignore TS2339
    if (episodeRuntime == 0 || _.isNaN(this.__currentTime)) return false;

    // 全体の95%を超えた状態、あるいは、エンドクレジットを超えた状態
    // @ts-ignore TS2339
    const endingStartPosition = this.state.playContext
      ? // @ts-ignore TS2339
        _.get(this.state.playContext.get('video'), 'mediaValues.endingStartPosition', 0)
      : 0;

    // ① 再生位置が視聴終了まで1分を切っている＆再生位置が全体尺の95％以上
    // episodeRuntime - this.__currentTime <= 60　生位置が視聴終了まで1分を切っている
    // this.__currentTime >= episodeRuntime * 0.95　再生位置が95％以上
    // @ts-ignore TS2339
    const condition1 = episodeRuntime - this.__currentTime <= 60 && this.__currentTime >= episodeRuntime * 0.95; // ② メディア側で設定されているエンディングスキップ位置 ≦ 再生位置となった場合
    // @ts-ignore TS2339
    // ② メディア側で設定されているエンディングスキップ位置 ≦ 再生位置となった場合
    //　endingStartPosition !== 0　は　falseをリターン
    //　endingStartPosition <= this.__currentTime　再生位置がエンディングスキップ位置を過ぎているかを確認し、値をリターン
    const condition2 = endingStartPosition !== 0 ? endingStartPosition <= this.__currentTime : false;
    // ① or ②の条件で視聴終了とする
    return condition1 || condition2;
  }

  addResume(reach_to_end_flag = false) {
    // @ts-ignore TS2339
    if (this.props.maybeEnableStartOver) return;
    // リニアの場合
    if (_.get(this.state, 'metadata.type') == 'linear_channel') return;
    // お試しの場合
    if (_.get(this.state, 'metadata.schemaId') === 6) return;

    // キャスト中はスキップする
    if (this._player.isGCasted()) return;

    //console.log(this.__currentTime);
    // @ts-ignore TS2339
    if (isNaN(this.__currentTime) || this.__currentTime === null) return;
    //console.log(this.state.metadata);
    // @ts-ignore TS2339
    if (!this.state.metadata) return;
    // mediaIdで再生時
    // @ts-ignore TS2339
    if (this.props.mediaOnly) return;

    // 再生前は登録しない
    if (!this.isPlayStarted) return;

    // 広告再生中は登録しない
    if (this.state.isAdMode) return;

    const authContext = this.context.getModelData('authContext');
    //console.log(authContext);
    if (!authContext) return;

    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    //console.log(profile);
    if (!profile) return;

    const analytics = _.get(this.state, 'activeVideo.analytics', {});

    // エンドクレジットを超えていたらreach_to_end_flag をtrue
    // const endingStartPosition = _.get(this.state.playContext.get('video'), 'mediaValues.endingStartPosition');
    // if (endingStartPosition && this.__currentTime > endingStartPosition) {
    //   reach_to_end_flag = true;
    // }

    // 95%, エンドクレジット, 1分ルール
    if (!reach_to_end_flag && this.isEnding()) {
      reach_to_end_flag = true;
    }

    // @ts-ignore TS2339
    let resumePoint = parseInt(this.__currentTime, 10);
    // DVR不可のライブの場合
    // @ts-ignore TS2339
    if (this.__duration === Infinity && !_.get(this.state.playContext.get('playbackRule'), 'enableStartOver', false)) {
      resumePoint = 0;
    }

    const params = {
      // @ts-ignore TS2339
      meta_id: this.state.metadata.id,
      user_id: profile.id,
      // @ts-ignore TS2339
      psid: analytics.psid,
      device_code: this.context.getModelData('browserInfo', 'deviceCode'),
      app_id: 1,
      resume_point: resumePoint,
      reach_to_end_flag,
      // @ts-ignore TS2339
      grouped_meta_id: this.state.metadata.groupedId,
    };
    // @ts-ignore TS2339
    if (analytics.vdid) {
      // @ts-ignore TS2339
      params['viewing_device_id'] = analytics.vdid;
    }
    const add = () => {
      //console.log('add');
      let cmsService = Object.assign({}, this.context.getModelData('services', 'cms'));
      cmsService.pathname = _.join(_.concat(cmsService.path, 'users/viewing_histories/add'), '/');

      const query = Object.keys(params)
        .map(function(key) {
          return key + '=' + encodeURIComponent(params[key]);
        })
        .join('&');

      if (window.fetch) {
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        if (authContext) {
          headers['Authorization'] = `Bearer ${authContext.token}`;
          headers['X-User-Id'] = authContext.id;
          headers['X-Session-Token'] = authContext.sessionToken;
        }
        window
          .fetch(url.format(cmsService), {
            method: 'POST',
            headers: headers,
            body: query,
            // @ts-ignore TS2339
            keepalive: this.__unloading,
          })
          .then(response => {
            // console.log(response);
            if (response.ok) {
              return response.json();
            }
            throw new Error('Network response was not ok.');
          })
          .then(response => {
            // console.log(response);
          })
          .catch(e => {
            console.log(e);
          });
      } else {
        const xhr = new XMLHttpRequest();
        // @ts-ignore TS2339
        xhr.open('POST', url.format(cmsService), !this.__unloading);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        if (authContext) {
          xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
          xhr.setRequestHeader('X-User-Id', authContext.id);
          xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
        }
        xhr.send(query);
      }
    };
    // @ts-ignore TS2339
    if (this.resumeTimer) {
      // @ts-ignore TS2339
      clearTimeout(this.resumeTimer);
      // @ts-ignore TS2339
      delete this.resumeTimer;
    }
    // @ts-ignore TS2339
    if (this.__unloading) {
      add();
    } else {
      // @ts-ignore TS2339
      this.resumeTimer = setTimeout(add, 1);
    }
  }

  startWaitingCurrentTimeCheck(retry = 0) {
    //console.log('startWaitingCurrentTimeCheck', retry);
    // @ts-ignore TS2339
    this.waitingTimeoutId = setTimeout(() => {
      this.checkWaitingCurrentTime(retry);
    }, 500);
  }

  stopWaitingCurrentTimeCheck() {
    //console.log('stopWaitingCurrentTimeCheck');
    // @ts-ignore TS2339
    if (this.waitingTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.waitingTimeoutId);
      // @ts-ignore TS2339
      delete this.waitingTimeoutId;
    }
  }

  checkWaitingCurrentTime(retry = 0) {
    //console.log('checkWaitingCurrentTime', retry, this.__waitingCurrentTime, this._player.currentTime(), this.state.rebuffering, this.state.paused);
    // ローディング中かつ再生中
    // @ts-ignore TS2339
    if (this.state.rebuffering && !this.state.paused && this.__waitingCurrentTime) {
      // 時間経過している
      // @ts-ignore TS2551
      if (this.__waitingCurrentTime < this._player.currentTime()) {
        this.setState({ rebuffering: false });
        // 時間が同じ場合はリトライする
        // @ts-ignore TS2551
      } else if (this.__waitingCurrentTime == this._player.currentTime()) {
        if (retry < 10) {
          this.startWaitingCurrentTimeCheck(++retry);
          return;
        } else {
          this.forceStopPlaying();
        }
      }
    }
    // @ts-ignore TS2551
    delete this.__waitingCurrentTime;
  }

  startCheckRebufferingTime() {
    const quarterOfHourMs = 15 * 60 * 1000;
    // @ts-ignore TS2551
    if (this.checkRebufferingTimerId) {
      // @ts-ignore TS2551
      clearTimeout(this.checkRebufferingTimerId);
      // @ts-ignore TS2551
      delete this.checkRebufferingTimerId;
    }
    // @ts-ignore TS2551
    this.checkRebufferingTimerId = setTimeout(this.forceStopPlaying, quarterOfHourMs);
  }
  stopCheckRebufferingTime() {
    // @ts-ignore TS2551
    if (this.checkRebufferingTimerId) {
      // @ts-ignore TS2551
      clearTimeout(this.checkRebufferingTimerId);
      // @ts-ignore TS2551
      delete this.checkRebufferingTimerId;
    }
  }

  startCheckBlurTime() {
    const oneday = 24 * 60 * 60 * 1000;
    // @ts-ignore TS2339
    if (this.checkBlurTimerId) {
      // @ts-ignore TS2339
      clearTimeout(this.checkBlurTimerId);
      // @ts-ignore TS2339
      delete this.checkBlurTimerId;
    }
    // @ts-ignore TS2339
    this.checkBlurTimerId = setTimeout(this.forceStopPlaying, oneday);
  }

  stopCheckBlurTime() {
    // @ts-ignore TS2339
    if (this.checkBlurTimerId) {
      // @ts-ignore TS2339
      clearTimeout(this.checkBlurTimerId);
      // @ts-ignore TS2339
      delete this.checkBlurTimerId;
    }
  }

  forceStopPlaying() {
    // @ts-ignore TS2339
    if (this._player) this._player.pause();
    // @ts-ignore TS2339
    this.props.showModal(<PageUpdateModal />, {
      enableCloseWithOutsidePointerDown: false,
    });
  }

  get isSpBrowser() {
    const { isAndroid, isIOS, isRequestDesktopWebsite } = this.context.getModelData('browserInfo') || {};
    return isAndroid || isIOS || isRequestDesktopWebsite;
  }

  render() {
    const playerStyle = {};
    // @ts-ignore TS2339
    if (this.state.artwork && (!this.state.fetching || this.state.fetching !== undefined)) {
      // @ts-ignore TS2339
      playerStyle.backgroundImage = `url(${this.state.artwork})`;
    }

    let errorView;
    // @ts-ignore TS2339
    if (this.state.error) {
      errorView = (
        <ErrorView
          // @ts-ignore TS2339
          error={this.state.error}
          // @ts-ignore TS2339
          commentActive={this.props.commentActive}
          // @ts-ignore TS2339
          watchPartyMode={this.props.watchPartyMode}
        />
      );
      // @ts-ignore TS2339
    } else if (this.state.errorView) {
      // @ts-ignore TS2339
      errorView = this.state.errorView;
    }

    // 再生マークの表示
    let isExerciseAlert = false;
    if (_.get(errorView, 'props.error.type') === ERROR.EXERCISE_ALERT) {
      isExerciseAlert = true;
      errorView = undefined;
    }

    let playToggleButton;
    // @ts-ignore TS2339
    if (this.state.playStartCompleted) {
      // @ts-ignore TS2339
      if (this.state.seeking) {
        // @ts-ignore TS2339
        playToggleButton = this.state.paused ? 'pause' : 'play';
        // @ts-ignore TS2339
      } else if (this.state.ended) {
        playToggleButton = 'ended';
        // @ts-ignore TS2339
      } else if (this.state.paused) {
        playToggleButton = 'pause';
      } else {
        playToggleButton = 'play';
      }
    } else {
      playToggleButton = 'pause';
    }

    // @ts-ignore TS2551
    const rendition = this.renditions[this.state.quality];
    // @ts-ignore TS2339
    let minBitrate = this.props.minBitrate || rendition.min_bitrate;
    // @ts-ignore TS2339
    let maxBitrate = this.props.maxBitrate || rendition.max_bitrate;
    // 自動、最高画質、高画質の場合
    // ソニー・ピクチャーズ エンタテインメントの場合
    // 標準(480)でキャップする
    // const contentsProviderIds = this.context.getModelData('contentsProviderIds');
    // if (this.state.quality < 3 && _.get(this.state.metadata, 'contentsProviderId') == contentsProviderIds.spe) {
    //   minBitrate = this.renditions[3].min_bitrate;
    //   maxBitrate = this.renditions[3].max_bitrate;
    // }

    const browserInfo = this.context.getModelData('browserInfo');
    let enableQualityChange = true;
    if (browserInfo.isSafari) {
      enableQualityChange = false;
    }

    // let enableClosedCaption = this.state.enableClosedCaption;
    // let captionLanguage = this.state.captionLanguage;
    // @ts-ignore TS2339
    let { textTrack, audioTrack, playbackRate } = this.state || {};
    // @ts-ignore TS2339
    let enableAutoplay = this.props.enableAutoplay;

    // @ts-ignore TS2339
    let mediaValues = this.state.playContext ? _.get(this.state.playContext.get('video'), 'mediaValues') : {};

    // キャスト時の設定
    let isSelfGCasted = false;
    let isGCastMediaSessoin = false;
    if (this._player.isGCasted()) {
      try {
        const authContext = this.context.getModelData('authContext');
        // @ts-ignore TS2339
        isSelfGCasted = this.props.player.isSelfGCasted(_.get(authContext, 'id'), true);
        isGCastMediaSessoin = isSelfGCasted;
      } catch (e) {
        // endedした時にmediaSessionが取れなくなる
        // mediaSessionがない場合は操作できないのでボタン制御する
        if (e == 'mediaSession is null') {
          isSelfGCasted = true;
        }
      }

      enableQualityChange = false;
      // @ts-ignore TS2339
      const settings = this._player.settings();
      // enableClosedCaption = _.includes(settings.subtitleLanguage, 'ja_cc');
      // captionLanguage = _.includes(settings.subtitleLanguage, 'ja') ? 'ja' : _.includes(settings.subtitleLanguage, 'en') ? 'en' : 'none';
      textTrack = settings.subtitleLanguage || 'none';
      audioTrack = settings.audioLanguage;

      enableAutoplay = settings.autoAdvance;
      if (_.has(settings, 'playbackRate')) playbackRate = settings.playbackRate;

      const playbackData = this.context.playerApp.playbackData();
      mediaValues = {
        videoSkips: _.get(playbackData, 'media.values.video_skips'),
        openingEndPosition: _.get(playbackData, 'media.values.opening_end_position'),
        endingStartPosition: _.get(playbackData, 'media.values.ending_start_position'),
      };
      // if (activeVideo) {
      //   activeVideo.seekPreview = {
      //     urls: _.get(playbackData, 'media.thumbnail_tile_urls', []),
      //   }
      // }
    }

    let showPlaybackRateChange = true;
    let enablePlaybackRateChange;
    if (browserInfo.isIE && (browserInfo.isWindows7 || browserInfo.isWindows8)) {
      enablePlaybackRateChange = false;
    } else {
      enablePlaybackRateChange =
        // @ts-ignore TS2339
        !this.state.playContext ||
        // @ts-ignore TS2339
        (this.state.playContext && this.state.playContext.get('playbackRule').enablePlaybackRateChange !== false);

      // 速度変更できない時は1に戻す
      if (!enablePlaybackRateChange) {
        // @ts-ignore TS2339
        this.state.playbackRate = 1;
      }
    }

    let enableOpeningVideoSkip = true;
    // @ts-ignore TS2339
    if (this.props.watchPartyMode) {
      // WPの場合は参加者側に[本編へスキップ]の項目を表示させない
      if (!this.context.watchPartyApp.isMaster()) {
        enableOpeningVideoSkip = false;
      }
      // WPの場合は設定に速度変更の項目を表示させない
      enablePlaybackRateChange = false;
      // WPの場合は速度を1に戻す
      playbackRate = 1;
    }

    // @ts-ignore TS2339
    let controls = this.props.controls;
    // playback_session_idが空のとき
    const noPsid =
      // @ts-ignore TS2339
      !errorView && this.state.playContext && !this.state.playContext.get('session').id && !this._player.isGCasted();
    // /pvでspからの再生がエラーした場合
    // @ts-ignore TS2339
    const isPvSpError = this.state.error && this.props.isPV && !this._player.isGCasted() && this.isSpBrowser;

    if (noPsid || isPvSpError) {
      // @ts-ignore TS2339
      const metaId = this.props.id || this.state.metadata.id;

      // Andorid, iOSからのアクセスの場合は、アプリ遷移ComponentをerrorViewで表示する
      if (this.isSpBrowser) {
        controls = false;
        errorView = (
          <ErrorView
            error={{ type: ERROR.CONTENTS_PROTECTION }}
            metaId={metaId}
            // @ts-ignore TS2339
            metadata={this.state.metadata}
            // @ts-ignore TS2339
            player={this._player}
            onClickCastButton={this.onClickCastButton}
            // @ts-ignore TS2339
            commentActive={this.props.commentActive}
            // @ts-ignore TS2339
            watchPartyMode={this.props.watchPartyMode}
          />
        );

        // その他の場合は未対応ブラウザerrorViewを表示する
      } else {
        errorView = (
          <ErrorView
            error={{ type: ERROR.BROWSER_NOT_SUPPORT }}
            // @ts-ignore TS2339
            commentActive={this.props.commentActive}
            // @ts-ignore TS2339
            watchPartyMode={this.props.watchPartyMode}
          />
        );
      }
    }

    // 配信前の状態の場合はサムネイルを暗くさせない
    const isBeforeDelivery =
      ERROR.META_NOT_DELIVERY_STARTED === _.get(errorView, 'props.error.type') && browserInfo.isSmartPhone;

    // controlの表示制御
    let isActiveControls =
      // @ts-ignore TS2339
      (this.state.uiState === 'active' && this.state.playStartCompleted) ||
      // @ts-ignore TS2339
      (this.state.uiState === 'active' && this.state.paused) ||
      (this.state.isAdMode && this.state.paused) ||
      // @ts-ignore TS2339
      this.state.ended ||
      // @ts-ignore TS2339
      (this.state.metadata && this.state.metadata.audioOnly) ||
      // @ts-ignore TS2339
      this.props.upnextView ||
      this._player.isGCasted();

    // モーダル表示する場合
    // @ts-ignore TS2339
    if (this.props.playerModal) {
      isActiveControls = false;
    }

    let enableStartOver =
      // @ts-ignore TS2339
      !this.state.playContext ||
      // @ts-ignore TS2339
      (this.state.playContext && this.state.playContext.get('playbackRule').enableStartOver !== false);
    let enableSeek =
      // @ts-ignore TS2339
      !this.state.playContext ||
      // @ts-ignore TS2339
      (this.state.playContext && this.state.playContext.get('playbackRule').enableSeek !== false);
    let enablePause =
      // @ts-ignore TS2339
      !this.state.playContext ||
      // @ts-ignore TS2339
      (this.state.playContext && this.state.playContext.get('playbackRule').enablePause !== false);
    if (this._player.isGCasted()) {
      // @ts-ignore TS2339
      enableSeek = _.get(this._player.playbackRule(), 'enableSeek', true) !== false;
      // @ts-ignore TS2339
      enablePause = _.get(this._player.playbackRule(), 'enablePause', true) !== false;
    }

    // リニアの場合は連続再生と速度とシークと一時停止はルールとは関係なく非表示
    // @ts-ignore TS2339
    let onToggleAutoplay = this.props.onToggleAutoplay;
    if (_.get(this.state, 'metadata.type') == 'linear_channel') {
      onToggleAutoplay = null;
      enableSeek = false;
      enablePause = false;
    }

    // DVR不可、シーク可だけどLive中ならシークと一時停止は不可
    // @ts-ignore TS2339
    if (!enableStartOver && enableSeek && this._player.duration() === Infinity) {
      enableSeek = false;
      enablePause = false;
    }

    // @ts-ignore TS2339
    this.enableSeek = enableSeek;
    // @ts-ignore TS2339
    this.enablePause = enablePause;

    // シーク不可の場合は速度切り替えを非表示
    if (!enableSeek) {
      showPlaybackRateChange = false;
      // @ts-ignore TS2339
      this.state.playbackRate = 1;
    }

    // @ts-ignore TS2339
    if (this.props.videoSkip) {
      // @ts-ignore TS2339
      if (this.state.autoHideVideoSkip) {
        // @ts-ignore TS2339
        this.firstSkipFlg = false;
        // @ts-ignore TS2339
      } else if (!isActiveControls && this.firstSkipFlg == undefined) {
        // @ts-ignore TS2339
        this.firstSkipFlg = true;
      }
    } else {
      // @ts-ignore TS2339
      this.firstSkipFlg = undefined;
    }

    // ローディングの表示制御
    let isBuffering =
      // @ts-ignore TS2339
      this.state.fetching ||
      // @ts-ignore TS2339
      this.state.loading ||
      // @ts-ignore TS2339
      this.state.rebuffering ||
      // @ts-ignore TS2339
      this.state.seeking ||
      // @ts-ignore TS2339
      this.state.gCastLoading;

    let playerPlayNode = null;
    if (isExerciseAlert) {
      isBuffering = false;
      // 但しダイアログが表示されている時は表示しない
      // @ts-ignore TS2339
      if (!this.props.isShowExerciseModal) {
        playerPlayNode = (
          <div className="play-ring-wapper" onClick={this.onClickReload}>
            <div className="play-ring">
              <i className="fa fa-play" />
            </div>
          </div>
        );
      }
    } else if (
      !errorView &&
      ((this._player && this._player.localPlayer()) || this._player.isGCasted()) &&
      !this.isPlayStarted
    ) {
      playerPlayNode = (
        <div className="play-ring-wapper" onClick={this.onClickPlay}>
          <div className="play-ring">
            <i className="fa fa-play" />
          </div>
        </div>
      );
    }

    // モーダル表示する場合
    // @ts-ignore TS2339
    if (this.props.playerModal) {
      isBuffering = false;
      playerPlayNode = null;
    }

    // ピクチャインピクチャが可能か
    let canPip = false;
    // @ts-ignore TS2339
    if (this.state.playContext && this.state.playContext.get('playbackRule').enablePip !== false) {
      // ブラウザが対応しているか
      if (typeof window !== 'undefined' && 'exitPictureInPicture' in document) {
        canPip = true;
      }
    }

    // ライブ時のオーバーレイ画像
    let enableLiveOlImage = false;
    // @ts-ignore TS2339
    if (this._player && this._player.duration() === Infinity) {
      // DVRはコントローラのliveに合わせる
      if (enableSeek) {
        try {
          // @ts-ignore TS2339
          const seekableEnd = this._player.seekable().end(0);
          // @ts-ignore TS2339
          if (seekableEnd - this._player.currentTime() < 10) {
            enableLiveOlImage = true;
          }
        } catch (e) {}
      } else {
        enableLiveOlImage = true;
      }
    }

    let liveOlImage = null;
    if (
      enableLiveOlImage &&
      // @ts-ignore TS2339
      this.state.playStartCompleted &&
      // @ts-ignore TS2339
      !(this.state.prepareLoading || this._player.isGCasted()) &&
      // @ts-ignore TS2339
      this.state.metadata.liveOlImage &&
      // @ts-ignore TS2339
      this.state.metadata.liveOlPosition !== 0
    ) {
      liveOlImage = (
        <img
          className={classnames('ol-image', {
            // @ts-ignore TS2339
            topRight: this.state.metadata.liveOlPosition == 3,
            // @ts-ignore TS2339
            topLeft: this.state.metadata.liveOlPosition == 1,
            // @ts-ignore TS2339
            bottomRight: this.state.metadata.liveOlPosition == 4,
            // @ts-ignore TS2339
            bottomLeft: this.state.metadata.liveOlPosition == 2,
            // @ts-ignore TS2339
            'hover-controls': this.state.hoverControls,
          })}
          // @ts-ignore TS2339
          src={this.state.metadata.liveOlImage}
          key={`ol-image`}
        />
      );
    }

    // @ts-ignore TS2339
    const isSameTitle = this.props.metadata.title === this.props.metadata.playableTitle;

    return (
      <div
        className={classnames(
          'wod-player',
          {
            active: isActiveControls,
            // @ts-ignore TS2339
            loading: this.state.fetching || this.state.fetching == undefined,
            // @ts-ignore TS2339
            'audio-wod-player': this.state.metadata && this.state.metadata.audioOnly,
            'is-ad-playing': this.state.isAdMode,
          },
          // @ts-ignore TS2339
          this.props.classes,
        )}
        style={playerStyle}
        tabIndex={0}
        // @ts-ignore TS2339
        ref={elem => (this.playerElement = elem)}
        onKeyDown={this.handleKeyDown}
      >
        {/*
         // @ts-ignore TS2339 */}
        {this.state.prepareLoading || this._player.isGCasted() ? (
          <div
            className={classnames('cast-player-container video-container', {
              // @ts-ignore TS2339
              'prp-started': this.state.playStartCompleted,
              'prp-error': !!errorView,
            })}
          >
            <img src="/images/gcast_bg.png" />
          </div>
        ) : (
          <WodPlayerCore
            // @ts-ignore TS2339
            setWillPlayAd={this.setWillPlayAd}
            // @ts-ignore TS2339
            ref={this.playerRef}
            // @ts-ignore TS2322
            maybeEnableStartOver={this.props.maybeEnableStartOver}
            // @ts-ignore TS2339
            mediaOnly={this.props.mediaOnly}
            // @ts-ignore TS2339
            startTime={this.props.startTime}
            // @ts-ignore TS2339
            shouldPlayAd={this.props.shouldPlayAd}
            // @ts-ignore TS2339
            muted={this.state.muted}
            // @ts-ignore TS2339
            volume={this.state.volume}
            // @ts-ignore TS2339
            paused={this.state.paused}
            // @ts-ignore TS2339
            loaded={this.state.loaded}
            // @ts-ignore TS2339
            playStartCompleted={this.state.playStartCompleted}
            // @ts-ignore TS2339
            ended={this.state.ended}
            minBitrate={minBitrate}
            maxBitrate={maxBitrate}
            // @ts-ignore TS2339
            playbackRate={this.state.playbackRate}
            // @ts-ignore TS2339
            activeVideo={this.state.activeVideo}
            // @ts-ignore TS2339
            playContext={this.state.playContext}
            // @ts-ignore TS2339
            isFullScreen={this.state.isFullScreen}
            isBeforeDelivery={isBeforeDelivery}
            canPip={canPip}
            // setRenditions={this.setRenditions}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            // @ts-ignore TS2339
            onLoadStart={this.props.onPlayerLoadStart}
            onLoaded={this.onPlayerLoaded}
            onReady={this.onPlayerReady}
            onPlay={this.onPlayerPlay}
            onPause={this.onPlayerPause}
            onPlaying={this.onPlayerPlaying}
            onFirstplay={this.onPlayerFirstplay}
            onRebuffering={this.onPlayerRebuffering}
            onStateChange={this.onPlayerStateChange}
            onVolumeChange={this.onPlayerVolumeChange}
            onTimeUpdate={this.onPlayerTimeUpdate}
            onDurationChange={this.onPlayerDurationChange}
            onError={this.onPlayerError}
            onPlaybackRateChange={this.onPlayerPlaybackRateChange}
            onEnded={this.onPlayerEnded}
            errorView={errorView}
          />
        )}
        {/*
         // @ts-ignore TS2339 */}
        {this._player.isGCasted() && !this.state.ended ? (
          <div className="cast-device-display">
            <div className="cast-device-display__inner">
              <div className="cast-device-display__inner__deviceName">
                <i className="fa fa-cast_connected"></i>
                {this._player.getGCastDeviceName()} {/*
                 // @ts-ignore TS2339 */}
                {this.state.playStartCompleted && isGCastMediaSessoin ? 'で再生しています' : 'に接続しました'}
              </div>
              {/*
               // @ts-ignore TS2339 */}
              {this.props.metadata ? (
                <React.Fragment>
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.props.metadata.type !== 'linear_channel' && this.props.metadata.title && !isSameTitle ? (
                    // @ts-ignore TS2339
                    <div className="cast-device-display__inner__name">{this.props.metadata.title}</div>
                  ) : null}
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.props.metadata.playableTitle ? (
                    <div className="cast-device-display__inner__playableTitle">
                      {/*
                       // @ts-ignore TS2339 */}
                      {isSameTitle ? this.props.metadata.title : this.props.metadata.playableTitle}
                    </div>
                  ) : null}
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.props.metadata.description ? (
                    // @ts-ignore TS2339
                    <div className="cast-device-display__inner__description">{this.props.metadata.description}</div>
                  ) : null}
                </React.Fragment>
              ) : null}
            </div>
          </div>
        ) : null}
        {!errorView ? <Spinner isBuffering={isBuffering} /> : null}
        {/*
         // @ts-ignore TS2339 */}
        {!errorView && this._player && !_.get(this.context.getModelData('browserInfo'), 'isMobile', false) ? (
          // @ts-ignore TS2339
          <Bezel action={this.state.action} ready={this.state.ready} autoPlayErrorFlg={this.autoPlayErrorFlg} />
        ) : null}
        {playerPlayNode}
        {errorView}
        {/*
         // @ts-ignore TS2339 */}
        {this.props.nextEpisode && !browserInfo.isAndroid && !browserInfo.isIOS ? (
          <React.Fragment>
            {isActiveControls ? null : <div className="next-episode-gra"></div>}
            <div
              className={classnames('next-episode', {
                'active-controls': isActiveControls,
                // @ts-ignore TS2339
                'hover-controls': this.state.hoverControls,
              })}
            >
              {/*
               // @ts-ignore TS2339 */}
              {this.props.nextEpisode}
            </div>
          </React.Fragment>
        ) : null}
        {liveOlImage}
        {/*
         // @ts-ignore TS2339 */}
        {enableOpeningVideoSkip && this.props.videoSkip && _.isEmpty(this.props.nextEpisode) ? (
          <div
            className={classnames('opening-skip', {
              isActiveControls: isActiveControls,
              // @ts-ignore TS2339
              show: this.firstSkipFlg,
              // @ts-ignore TS2339
              'hover-controls': this.state.hoverControls,
              // @ts-ignore TS2339
              hidden: this.state.viewMetadata,
            })}
            onClick={this.onClickVideoSkip}
            tabIndex={0}
            onKeyDown={this.onKeydownVideoSkip}
          >
            <i className="fa fa-forward"></i>本編へスキップ
          </div>
        ) : null}
        {/*
         // @ts-ignore TS2339 */}
        {this.state.playStartCompleted && !this.state.ended && !this.props.playerSideListMode ? (
          <PlayerSideListButton
            // @ts-ignore TS2322
            model={this.props.model}
            // @ts-ignore TS2339
            handlePlayerSideListModeChange={this.props.handlePlayerSideListModeChange}
            // @ts-ignore TS2339
            meta={this.props.metadata}
            isActiveControls={isActiveControls}
            // @ts-ignore TS2339
            hoverControls={this.state.hoverControls}
          />
        ) : null}
        {controls ? (
          <WodPlayerControls
            // @ts-ignore TS2322
            playNextButtonLabel={this.props.playNextButtonLabel}
            // @ts-ignore TS2339
            ref={this.playerControlRef}
            handleKeyDown={this.handleKeyDown}
            onKeyboardFocus={this.handleKeyboardFocus}
            // @ts-ignore TS2339
            config={this.props.config}
            // @ts-ignore TS2551
            renditions={this.renditions}
            // @ts-ignore TS2339
            activeVideo={this.state.activeVideo}
            // @ts-ignore TS2339
            metadata={this.state.metadata}
            // @ts-ignore TS2339
            audioTracks={this.state.audioTracks}
            // @ts-ignore TS2339
            exitPlayer={this.props.exitPlayer}
            // toggleMode={this.props.toggleMode}
            // @ts-ignore TS2339
            showMetadataDetail={this.props.showMetadataDetail}
            // @ts-ignore TS2339
            getEvidence={this.props.getEvidence}
            // @ts-ignore TS2339
            hasPreplay={this.props.hasPreplay}
            isActive={isActiveControls}
            isAdMode={this.state.isAdMode}
            // @ts-ignore TS2339
            adDuration={this.state.adDuration}
            // @ts-ignore TS2339
            currentAdTime={this.state.currentAdTime}
            // @ts-ignore TS2339
            uiState={this.state.uiState}
            // @ts-ignore TS2339
            paused={this.state.paused}
            // @ts-ignore TS2339
            muted={this.state.muted}
            // @ts-ignore TS2339
            ended={this.state.ended}
            isSelfGCasted={isSelfGCasted}
            isGCastMediaSessoin={isGCastMediaSessoin}
            // @ts-ignore TS2339
            playStartCompleted={this.state.playStartCompleted}
            // @ts-ignore TS2339
            isFullScreen={this.state.isFullScreen}
            canPip={canPip}
            // @ts-ignore TS2339
            onCastToTarget={this.props.onCastToTarget}
            // @ts-ignore TS2339
            onClickPlayNext={this.props.onClickPlayNext}
            onClickPlay={this.onClickPlay}
            onClickReload={this.onClickReload}
            onClickVolume={this.onClickVolume}
            onClickCastButton={this.onClickCastButton}
            // @ts-ignore TS2339
            onClickCommentButton={this.props.onClickCommentButton}
            // @ts-ignore TS2339
            commentActive={this.props.commentActive}
            // @ts-ignore TS2339
            watchPartyMode={this.props.watchPartyMode}
            // @ts-ignore TS2339
            watchPartyType={this.props.watchPartyType}
            onClickSeekBack={this.onClickSeekBack}
            onClickSeekForward={this.onClickSeekForward}
            onMouseMove={this.onMouseMove}
            onMouseLeave={this.onMouseLeave}
            showControlsOnMobileDevices={this.showControlsOnMobileDevices}
            // @ts-ignore TS2339
            onClickTouchDeviceControl={this.onClickTouchDeviceControl}
            // @ts-ignore TS2339
            onUserActivityChanged={this.props.onUserActivityChanged}
            // @ts-ignore TS2339
            isInPictureInPicture={this.state.isInPictureInPicture}
            togglePictureInPicture={this.togglePictureInPicture}
            toggleFullScreen={this.toggleFullScreen}
            // @ts-ignore TS2339
            playNextTitle={this.props.playNextTitle}
            // @ts-ignore TS2339
            mode={this.props.mode}
            enableSeek={enableSeek}
            enablePause={enablePause}
            // @ts-ignore TS2339
            enableFullScreen={this.props.enableFullScreen}
            // @ts-ignore TS2339
            playbackEnded={this.state.ended}
            playToggleButton={playToggleButton}
            // @ts-ignore TS2339
            playbackTimedOut={this.state.playbackTimedOut}
            playbackRate={playbackRate}
            // @ts-ignore TS2339
            player={this._player}
            // @ts-ignore TS2339
            ready={this.state.ready}
            // @ts-ignore TS2339
            volume={this.state.volume}
            enableTextTracks={this.enableTextTracks}
            enableAudioTracks={this.enableAudioTracks}
            textTrack={textTrack}
            audioTrack={audioTrack}
            // @ts-ignore TS2339
            currentScrubTime={this.state.currentScrubTime}
            updateCurrentScrubTime={this.updateCurrentScrubTime}
            enableAutoplay={enableAutoplay}
            onToggleAutoplay={onToggleAutoplay}
            enableQualityChange={enableQualityChange}
            enablePlaybackRateChange={enablePlaybackRateChange}
            showPlaybackRateChange={showPlaybackRateChange}
            // @ts-ignore TS2339
            quality={this.state.quality}
            onChangeQuality={this.onChangeQuality}
            setKeepActive={this.setKeepActive}
            // @ts-ignore TS2339
            multiView={this.state.playContext ? this.state.playContext.get('video').multiView : null}
            // @ts-ignore TS2339
            upnextView={this.props.upnextView}
            // @ts-ignore TS2339
            watchCard={this.props.watchCard}
            onHover={this.handleHoverControls}
            // @ts-ignore TS2339
            showModal={this.props.showModal}
            // @ts-ignore TS2339
            closeModal={this.props.closeModal}
            // @ts-ignore TS2339
            watchSpMode={this.props.watchSpMode}
            // @ts-ignore TS2339
            nextEpisode={this.props.nextEpisode}
            // @ts-ignore TS2339
            linearChannelMetaIds={this.props.linearChannelMetaIds}
            // @ts-ignore TS2339
            mediaQueryCheck={this.props.mediaQueryCheck}
            // @ts-ignore TS2339
            model={this.props.model}
          />
        ) : null}
        {/*
         // @ts-ignore TS2339 */}
        {this.props.playerModal && (
          <div className="wod-player-error-view">
            <div className="error-view">
              <div className="error-view-content">
                <div className="error-view-content__dialog">
                  <div className="error-view-body">
                    {/*
                     // @ts-ignore TS2339 */}
                    <div className="error-view-detail">{this.props.playerModal}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WodPlayer;
