import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/libs/util';

import HeroImages from './HeroImages';
import activeTags from '../../utils/activeTags';
import canAccessBySlug from '../../utils/slug';
import EventInfo from './Epg/EventInfo';
import EpgEvent from './Epg/EpgEvent';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import activeProfile from '../../../../utils/activeProfile';
import { CLICK_AREA, CONTENT_EVENTS } from '../../../../common/GtmApp';
import SetDate from './SetDate';

const popTitleArtStyle = function(zoom) {
  const opacity = zoom ? 0 : 1;
  return {
    opacity: `${opacity}`,
    transitionDelay: '0ms',
    transitionTimingFunction: 'linear',
    transitionDuration: '400ms',
  };
};

export default class PopEventCard extends Component {
  static getPaths = function(models, options, props = {}) {
    let path = [];
    // @ts-ignore TS2339
    if (props.titleId) {
      // @ts-ignore TS2339
      path = path.concat(['meta', props.titleId]);
      // @ts-ignore TS2339
    } else if (props.titleModel && props.titleModel.uniqueId) {
      // @ts-ignore TS2339
      path = path.concat(['event', props.titleModel.uniqueId]);
    }
    let paths = [
      path.concat([
        [
          'name',
          'shortMessage',
          'description',
          'header',
          'shortName',
          'schemaId',
          'thumbnailUrl',
          'leadEpisodeId',
          'edgeEpisodeId',
          'leadEpisode',
          'edgeEpisode',
          'genres',
          'middleGenres',
          'attributes',
          'type',
          'schemaId',
          'mylisted',
          'canMyList',
          'cardInfo',
        ],
      ]),
    ];
    // @ts-ignore TS2339
    if (props.titleModel) {
      // @ts-ignore TS2339
      if (props.artKind === 'edge_episode' && props.titleModel.edgeEpisodeId) {
        paths = paths.concat([
          [
            'meta',
            // @ts-ignore TS2339
            props.titleModel.edgeEpisodeId,
            [
              'id',
              'refId',
              'name',
              'shortName',
              'schemaId',
              'genres',
              'middleGenres',
              'attributes',
              'thumbnailUrl',
              'cardInfo',
            ],
          ],
        ]);
        // @ts-ignore TS2339
      } else if (props.titleModel.leadEpisodeId) {
        paths = paths.concat([
          [
            'meta',
            // @ts-ignore TS2339
            props.titleModel.leadEpisodeId,
            [
              'id',
              'refId',
              'name',
              'shortName',
              'schemaId',
              'genres',
              'middleGenres',
              'attributes',
              'thumbnailUrl',
              'cardInfo',
            ],
          ],
        ]);
      }
      // @ts-ignore TS2339
      if (!!props.titleModel.schemaId) {
        // @ts-ignore TS2339
        if (props.titleModel.schemaId === 2 || props.titleModel.schemaId === 1) {
          paths = paths.concat([
            [
              'meta',
              // @ts-ignore TS2339
              props.titleModel.id,
              'viewingEpisode',
              [
                'id',
                'refId',
                'name',
                'shortName',
                'schemaId',
                'genres',
                'middleGenres',
                'attributes',
                'bookmarkPosition',
                'thumbnailUrl',
                'cardInfo',
              ],
            ],
          ]);
        } else {
          paths = paths.concat([
            [
              'meta',
              // @ts-ignore TS2339
              props.titleModel.id,
              [
                'id',
                'name',
                'shortName',
                'schemaId',
                'genres',
                'middleGenres',
                'attributes',
                'resumePoint',
                'cardInfo',
              ],
            ],
          ]);
        }
      }
      // @ts-ignore TS2339
      if (props.titleModel.type === 'linear_channel') {
        // @ts-ignore TS2339
        paths = paths.concat(EventInfo.getPaths(models, options, { id: props.titleModel.id }));
      }
    }
    return paths;
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      history: PropTypes.object,
      isInitialRender: PropTypes.bool,
      isOverlayPage: PropTypes.bool,
      isTallRow: PropTypes.bool,
      listContext: PropTypes.string,
      listType: PropTypes.string,
      routeHandler: PropTypes.object,
      rowNum: PropTypes.number,
      deleteApp: PropTypes.object,
      models: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  static get propTypes() {
    return {
      isDoubleWide: PropTypes.bool,
      isTallPanel: PropTypes.bool,
      model: PropTypes.object.isRequired,
      onPopClose: PropTypes.func,
      onPopOpen: PropTypes.func,
      onJawOpen: PropTypes.func,
      titleCardImage: PropTypes.string,
      titleModel: PropTypes.object.isRequired,
      popType: PropTypes.string,
      classes: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
      artKind: PropTypes.string,
    };
  }

  static get defaultProps() {
    return {
      popType: 'zoom',
      artKind: 'default',
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      generation: -1,
      fetchDataError: null,
      rotateImages: true,
      showRatingProductization: true,
    };
    this.handleClickPlayButton = this.handleClickPlayButton.bind(this);
    this.handleDeleteSend = this.handleDeleteSend.bind(this);
    // @ts-ignore TS2339
    this.titleModel = _.clone(props.titleModel);
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
    // @ts-ignore TS2339
    if (this.animationId) {
      // @ts-ignore TS2339
      clearTimeout(this.animationId);
      // @ts-ignore TS2339
      delete this.animationId;
    }
    // @ts-ignore TS2339
    if (this.props.onPopClose) {
      // @ts-ignore TS2339
      this.props.onPopClose();
    }
  }

  sendToGtm(clickArea) {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-ignore TS2339
    const eventMeta = this.titleModel;
    const channel = _.get(eventMeta, 'linearChannelMeta');
    const content = {
      // @ts-ignore TS2339
      refId: _.get(this.titleModel, 'uniqueId'),
      // @ts-ignore TS2339
      name: _.get(this.titleModel, 'name'),
    };
    const attributes = _.get(eventMeta, 'attributes');
    const genres = _.get(eventMeta, 'genres');
    const middleGenres = _.get(eventMeta, 'middleGenres');
    const schemaId = _.get(eventMeta, 'schemaId');
    this.context.gtmApp.pushDataLayerOnContentPageClick(
      CONTENT_EVENTS.CONTENT_CLICK,
      { channel, content, attributes, genres, middleGenres, schemaId },
      { clickArea },
    );
  }

  render() {
    return (
      <div
        className={classnames([
          'pop-card',
          // @ts-ignore TS2339
          `pop-${this.props.popType}`,
          {
            'pop-card-tall-panel': this.context.isTallRow === true,
            'has-thumbs': true,
          },
          // @ts-ignore TS2339
          this.props.classes,
        ])}
      >
        {this.renderHeroImages()}
        <div className="pop-outline"></div>
        {/*
         // @ts-ignore TS2339 */}
        <img src={this.props.titleArt} className="pop-title-art" style={popTitleArtStyle(false)} />
        {this.renderOverlay()}
        {this.renderBounceArea()}
        <div
          className="hiddenActionItems"
          style={{
            display: 'none',
          }}
        ></div>
      </div>
    );
  }

  handleClickPlayButton(e) {
    e.preventDefault();
    e.stopPropagation();
    this.sendToGtm(CLICK_AREA.THUMBNAIL);
    // @ts-ignore TS2339
    if (this.titleModel) {
      let to;
      // @ts-ignore TS2339
      if (this.titleModel.isOnAir) {
        const simulcast = _.find(
          this.context.getModelData('simulcast'),
          // @ts-ignore TS2339
          item => item.refId == this.titleModel.linearChannelMeta.refId,
        );
        // @ts-ignore TS2554
        to = routes.simulcast.makePath({ channelName: simulcast.name });
      } else {
        const simulcast = _.find(
          this.context.getModelData('simulcast'),
          // @ts-ignore TS2339
          item => item.refId == this.titleModel.linearChannelMeta.refId,
        );
        // @ts-ignore TS2554
        to = routes.simulcastDetail.makePath({ channelName: simulcast.name, uniqueId: this.titleModel.uniqueId });
      }
      this.context.history.push(to);
    }
  }

  handleDeleteSend(e) {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore TS2339
    if (this.state.deleting) return false;
    this.setState({ deleting: true });

    const authContext = this.context.getModelData('authContext');
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    this.context.deleteApp
      // @ts-ignore TS2339
      .one(this.titleModel.id, authContext, profile.id)
      .then(response => {
        // @ts-ignore TS2339
        if (!this._isMounted) return;
        this.setState({ deleting: false });
      })
      .catch(e => {
        // @ts-ignore TS2339
        if (!this._isMounted) return;
        window.location.reload();
      });
  }

  renderHeroImages() {
    const getImages = () => {
      // @ts-ignore TS2339
      if (this.props.useTitleCardImage) {
        // @ts-ignore TS2339
        return [this.props.titleCardImage];
        // @ts-ignore TS2339
      } else if (_.get(this.viewingModel, 'thumbnailUrl')) {
        // @ts-ignore TS2339
        return [this.viewingModel.thumbnailUrl];
      } else if (
        // @ts-ignore TS2339
        _.get(this.titleModel, 'thumbnailUrl', _.get(this.titleModel, 'linearChannelMeta.thumbnailUrl')) &&
        // @ts-ignore TS2339
        this.props.artKind === 'default'
      ) {
        // @ts-ignore TS2339
        return [this.titleModel.thumbnailUrl || this.titleModel.linearChannelMeta.thumbnailUrl];
      }
      return null;
    };
    return (
      <HeroImages
        auto={true}
        classes={{ 'pop-background': true }}
        duration={3500}
        firstDelay={3500}
        // @ts-ignore TS2322
        height={480}
        isStandalone={false}
        // @ts-ignore TS2339
        preloadImage={this.props.titleCardImage}
        images={getImages()}
        size={'_665x375'}
        stop={false}
        watched={false}
        // @ts-ignore TS2339
        model={this.props.model}
      >
        {/*
         // @ts-ignore TS2339 */}
        {this.props.popType === 'bounce' ? this.renderPlayButton() : null}
      </HeroImages>
    );
  }

  renderPlayButton() {
    const simulcast = _.find(
      this.context.getModelData('simulcast'),
      // @ts-ignore TS2339
      item => item.refId == this.titleModel.linearChannelMeta.refId,
    );

    // @ts-ignore TS2339
    if (this.titleModel.isOnAir) {
      // @ts-ignore TS2339
      if (this.titleModel.linearChannelMeta.metaId) {
        return (
          <MainViewLink
            // @ts-ignore TS2322
            to={routes.simulcast}
            params={{ channelName: simulcast.name }}
            onClick={this.handleClickPlayButton}
            role="link"
            ariaLabel="再生"
            className="pop-play pop-play-top play-link"
          />
        );
      }
    }
  }

  getTitleModelId() {
    // @ts-ignore TS2339
    if (this.titleModel.type !== 'media' && this.titleModel.type !== 'linear_channel') {
      // @ts-ignore TS2339
      return canAccessBySlug(this.titleModel) ? this.titleModel.slug : this.titleModel.id;
    }
    // @ts-ignore TS2339
    return this.titleModel.id;
  }

  renderPopInfo() {
    let props = {};
    // @ts-ignore TS2339
    const cardInfo = this.titleModel.cardInfo || {};

    let channelName;
    const simulcast = _.find(
      this.context.getModelData('simulcast'),
      // @ts-ignore TS2339
      item => item.refId == this.titleModel.linearChannelMeta.refId,
    );
    if (simulcast) channelName = _.lowerCase(simulcast.name);
    let linkProps = {
      to: routes.simulcast,
      params: { channelName: simulcast.name },
    };
    // @ts-ignore TS2339
    if (this.titleModel.isOnAir) {
      // @ts-ignore TS2339
      if (this.titleModel.linearChannelMeta.metaId) {
        linkProps = {
          to: routes.simulcast,
          params: { channelName: simulcast.name },
        };
      }
      // @ts-ignore TS2339
    } else if (this.titleModel.unique_id) {
      linkProps = {
        to: routes.simulcastDetail,
        // @ts-ignore TS2322
        params: { channelName: simulcast.name, uniqueId: this.titleModel.uniqueId },
      };
    }

    const getLink = child => {
      // @ts-ignore TS2339
      if (this.titleModel.isOnAir) {
        // @ts-ignore TS2339
        if (this.titleModel.linearChannelMeta.metaId) {
          const simulcast = _.find(
            this.context.getModelData('simulcast'),
            // @ts-ignore TS2339
            item => item.refId == this.titleModel.linearChannelMeta.refId,
          );
          return (
            <MainViewLink
              // @ts-ignore TS2322
              className={classnames({ ellipsized: this.props.ellipsized })}
              to={routes.simulcast}
              params={{ channelName: simulcast.name }}
              tabIndex={-1}
            >
              {child}
            </MainViewLink>
          );
        }
        // @ts-ignore TS2339
      } else if (this.titleModel.uniqueId) {
        const simulcast = _.find(
          this.context.getModelData('simulcast'),
          // @ts-ignore TS2339
          item => item.refId == this.titleModel.linearChannelMeta.refId,
        );
        return (
          <MainViewLink
            // @ts-ignore TS2322
            className={classnames({ ellipsized: this.props.ellipsized })}
            to={routes.simulcastDetail}
            // @ts-ignore TS2339
            params={{ channelName: simulcast.name, uniqueId: this.titleModel.uniqueId }}
            tabIndex={-1}
          >
            {child}
          </MainViewLink>
        );
      }
      return <span>{child}</span>;
    };
    // @ts-ignore TS2339
    let title = this.titleModel.shortName || this.titleModel.name;

    return (
      <div className="pop-info" {...props}>
        <div className="pop-info-main">
          <div className="pop-text">
            <div className="pop-title">
              {channelName ? <div className={classnames('linear_logo', channelName)}></div> : null}
              {title ? (
                <React.Fragment>
                  {getLink(title)}
                  <i className="fa fa-angle_right"></i>
                </React.Fragment>
              ) : null}
            </div>
            <div className="meta">
              {/*
               // @ts-ignore TS2339 */}
              {this.titleModel.startAt && this.titleModel.endAt ? (
                <span className={classnames('event-time')}>
                  {/*
                   // @ts-ignore TS2322 */}
                  <SetDate date={this.titleModel.startAt} format={'short'} /> -{' '}
                  {/*
                   // @ts-ignore TS2322 */}
                  <SetDate date={this.titleModel.endAt} format={'time'} />
                </span>
              ) : null}
              {/* <Duration
                seasonCount={cardInfo.seasonCount}
                episodeCount={cardInfo.episodeCount}
                episodeRuntime={cardInfo.episodeRuntime} /> */}
              {this.renderRating()}
            </div>
            {/*
             // @ts-ignore TS2339 */}
            {_.get(this.titleModel, 'shortMessage') ? (
              // @ts-ignore TS2339
              <div className="synopsis">{this.titleModel.shortMessage}</div>
            ) : // @ts-ignore TS2339
            _.get(this.titleModel, 'header') ? (
              // @ts-ignore TS2339
              <div className="synopsis">{this.titleModel.header}</div>
            ) : // @ts-ignore TS2339
            _.get(this.titleModel, 'description') ? (
              // @ts-ignore TS2339
              <div className="synopsis">{this.titleModel.description}</div>
            ) : null}
            <div className="button-box">
              {/*
               // @ts-ignore TS2339 */}
              {this.props.myListButton ? this.props.myListButton : null}
              {this.context.listContext === 'continueWatching' ? (
                <div className="action-btn delete-button btn-very-small" onClick={this.handleDeleteSend}>
                  <i className="fa fa-delete"></i>
                  <span>削除</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRating() {
    // @ts-ignore TS2339
    if (_.get(this.titleModel, 'ritem')) {
      // @ts-ignore TS2339
      return <span className="rating">{this.titleModel.ritem}</span>;
    }
    return null;
  }

  renderOverlay() {
    // @ts-ignore TS2339
    const cardInfo = this.titleModel ? this.titleModel.cardInfo || {} : {};
    const userInfo = this.context.getModelData('userInfo');

    // @ts-ignore TS2339
    if (this.props.popType === 'bounce') {
      // @ts-ignore TS2339
      if (!!this.titleModel && Object.keys(this.titleModel).length > 0) {
        // @ts-ignore TS2339
        let tags = activeTags(this.titleModel, this.context);
        // @ts-ignore TS2339
        if (this.titleModel.type === 'linear_channel' && this.titleModel.isFirst) tags.unshift('初回');
        let cardBadge;
        if (!_.isEmpty(tags)) {
          // アートの上には一つのタグしか表示させない
          cardBadge = <div className="card-badge">{tags[0]}</div>;
        }
        return (
          <div className={classnames('pop-overlay', { noProgress: userInfo.status == 'NON_REGISTERED_MEMBER' })}>
            <div className="pop-play-hitzone" onClick={this.handleClickPlayButton}>
              {cardBadge}
              {this.renderPlayButton()}
            </div>
            <div className="title">
              {/*
               // @ts-ignore TS2339 */}
              <p className="ellipsized">{this.titleModel.shortName || this.titleModel.name}</p>
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      let channelName;
      const simulcast = _.find(
        this.context.getModelData('simulcast'),
        // @ts-ignore TS2339
        item => item.refId == this.titleModel.linearChannelMeta.refId,
      );
      if (simulcast) channelName = _.lowerCase(simulcast.name);

      let linkProps = {
        to: routes.simulcast,
        params: { channelName: simulcast.name },
      };
      // @ts-ignore TS2339
      if (this.titleModel.isOnAir) {
        // @ts-ignore TS2339
        if (this.titleModel.linearChannelMeta.metaId) {
          linkProps = {
            to: routes.simulcast,
            params: { channelName: simulcast.name },
          };
        }
        // @ts-ignore TS2339
      } else if (this.titleModel.unique_id) {
        linkProps = {
          to: routes.simulcastDetail,
          // @ts-ignore TS2322
          params: { channelName: simulcast.name, uniqueId: this.titleModel.uniqueId },
        };
      }
      // if (this.titleModel.schemaId === 10) {
      //   linkProps.to = routes.featureDetail;
      // }
      return (
        <div className={classnames('pop-overlay')}>
          <div className="pop-play-hitzone">{this.renderPlayButton()}</div>
          {/*
           // @ts-ignore TS2322 */}
          <MainViewLink {...linkProps} className="pop-titlerow-hitzone pop-titlerow-hitzone-half"></MainViewLink>
          {this.renderPopInfo()}
        </div>
      );
    }
  }

  renderBounceArea() {
    // @ts-ignore TS2339
    if (this.props.popType === 'bounce') {
      return (
        <div className="pop-bouncearea">
          <div className="pop-bumper" />
          {this.renderPopInfo()}
        </div>
      );
    } else {
      return null;
    }
  }

  async fetchData(props) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const eventInfoRootPath = EventInfo.getRootPath(this.context.models, {}, { id: props.titleModel.id });
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        if (!this.titleModel) this.titleModel = {};
        // @ts-ignore TS2339
        if (!this.viewingModel) this.viewingModel = {};
        let titleModel = _.get(res, ['json', 'meta', props.titleId], {});
        // 渡されたcardInfoを優先する
        // @ts-ignore TS2339
        if (this.titleModel.cardInfo) {
          delete titleModel.shortName;
          delete titleModel.cardInfo;
        }
        // @ts-ignore TS2339
        Object.assign(this.titleModel, titleModel);
        let onairEvent = _.get(res.json, eventInfoRootPath);
        // @ts-ignore TS2554
        onairEvent = EpgEvent.additionalData(onairEvent);
        // @ts-ignore TS2339
        if (this.titleModel.schemaId === 3 || this.titleModel.schemaId === 3) {
          // @ts-ignore TS2339
          Object.assign(this.viewingModel, this.titleModel);
          // @ts-ignore TS2339
        } else if (this.titleModel.viewingEpisode) {
          // @ts-ignore TS2339
          Object.assign(this.viewingModel, this.titleModel.viewingEpisode);
          // @ts-ignore TS2339
        } else if (props.artKind === 'edge_episode' && this.titleModel.edgeEpisodeId) {
          // @ts-ignore TS2339
          Object.assign(this.viewingModel, _.get(res, ['json', 'meta', this.titleModel.edgeEpisodeId]));
          // @ts-ignore TS2339
        } else if (this.titleModel.leadEpisode && this.titleModel.leadEpisode.id) {
          // @ts-ignore TS2339
          Object.assign(this.viewingModel, _.get(res, ['json', 'meta', this.titleModel.leadEpisode.id]));
          // @ts-ignore TS2339
        } else if (this.titleModel.leadEpisodeId) {
          // @ts-ignore TS2339
          Object.assign(this.viewingModel, _.get(res, ['json', 'meta', this.titleModel.leadEpisodeId]));
        } else if (onairEvent) {
          // @ts-ignore TS2339
          Object.assign(this.viewingModel, onairEvent);
        }
        const newState = {
          dispose: null,
          fetchDataError: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          dispose: null,
          fetchDataError: e,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }
}
