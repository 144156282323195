import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PresTrackedElement from '../../../common/components/PresTrackedElement';

import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import activeTags from '../../utils/activeTags';
import BackgroundImage from '../../../common/components/BackgroundImage';
import _ from 'src/libs/util';
import { getIsDelivery } from '../../../common/utils/metaUtil';

export default class WatchCard extends Component {
  static getPaths = function(models, options, props = {}) {
    let paths = [];
    // @ts-ignore TS2339
    if (props.id) {
      paths = paths.concat([
        [
          'meta',
          // @ts-ignore TS2339
          props.id,
          [
            'id',
            'refId',
            'type',
            'schemaId',
            'name',
            'shortName',
            'thumbnailUrl',
            'seriesMeta',
            'seasonMeta',
            'genres',
            'middleGenres',
            'attributes',
            'episodeNumber',
            'bookmarkPosition',
            'cardInfo',
          ],
        ],
      ]);
    }
    return paths;
  };

  static get propTypes() {
    return {
      showEpisodeDuration: PropTypes.bool,
      showEpisodeNumber: PropTypes.bool,
      showEpisodeSummary: PropTypes.bool,
      showEpisodeProgress: PropTypes.bool,
      enableTitleEllipsis: PropTypes.bool,
      rankNum: PropTypes.number,
      itemTabbable: PropTypes.bool,
      enableCardClick: PropTypes.bool,
      model: PropTypes.object,
      watchType: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      onCardClick: PropTypes.func,
    };
  }

  static get defaultProps() {
    return {
      showEpisodeDuration: true,
      showEpisodeNumber: true,
      showEpisodeSummary: true,
      showEpisodeProgress: true,
      enableTitleEllipsis: true,
      watchType: 'first',
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      history: PropTypes.object,
      models: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = {};
    // @ts-ignore TS2339
    this.countdownRef = React.createRef();
    this.handleClickPlayButton = this.handleClickPlayButton.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleMouseEnterClose = this.handleMouseEnterClose.bind(this);
    this.handleMouseLeaveClose = this.handleMouseLeaveClose.bind(this);

    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    if (!this.props.itemData) this.fetchData(this.props);

    // @ts-ignore TS2339
    if (this.props.watchType === 'next-video') {
      this.countdownTimer();
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (this.props.id != nextProps.id) {
      this.fetchData(nextProps);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleClickPlayButton(e) {
    e.preventDefault();
    e.stopPropagation();
    const { routeHandler, history } = this.context;
    const query = {};
    // 連続再生の場合は頭から再生を開始させる
    // @ts-ignore TS2339
    if (this.props.watchType === 'next-video') {
      // @ts-ignore TS2339
      query.t = 0;
      // @ts-ignore TS2339
      query.sc = 1;
      // @ts-ignore TS2339
      query.ec = this.props.eirinConfirmed;
      // @ts-ignore TS2339
    } else if (this.props.watchType === 'first' || this.props.watchType === 'latest') {
      // ピックアップエピソード経由の場合は視聴中のレジューム位置から開始させる
      if (this.context.getModelData('authContext')) {
        // @ts-ignore TS2339
        query.t = _.get(this.item, 'bookmarkPosition', 0);
      }
    }
    // @ts-ignore TS2339
    if (this.item.refId) {
      // @ts-ignore TS2554
      const to = routes.content.makePath({ id: this.item.refId }, query);
      if (routeHandler.path.indexOf('/content') === 0) {
        history.replace(to);
      } else {
        history.push(to);
      }
    } else {
      // @ts-ignore TS2554
      const to = routes.watchNow.makePath({ id: this.props.id }, query);
      if (routeHandler.path.indexOf('/watch') === 0) {
        history.replace(to);
      } else {
        history.push(to);
      }
    }
    // @ts-ignore TS2339
    if (this.props.onCardClick) this.props.onCardClick(this.item);
  }
  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleClickPlayButton(e);
    }
  }

  handleClickClose(e) {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore TS2339
    if (this.props.onClose) {
      // @ts-ignore TS2339
      this.props.onClose();
    }
  }

  handleMouseEnterClose(e) {
    this.setState({ hoverClose: true });
  }

  handleMouseLeaveClose(e) {
    this.setState({ hoverClose: false });
  }

  countdownTimer() {
    const update = () => {
      // @ts-ignore TS2339
      const el = this.countdownRef.current;
      // @ts-ignore TS2339
      if (el && this.props.player) {
        // @ts-ignore TS2345
        const count = parseInt(this.props.player.duration() - this.props.player.currentTime(), 10);
        el.innerText = _.max([count, 0]);
      }
    };
    update();
    // @ts-ignore TS2339
    this.countdownIntervalId = setInterval(() => {
      update();
    }, 1000);
  }

  render() {
    // @ts-ignore TS2339
    if (_.isEmpty(this.item)) return null;
    // @ts-ignore TS2339
    if (!this.item.cardInfo) this.item.cardInfo = {};
    let summary;
    // @ts-ignore TS2339
    if (this.props.showEpisodeSummary === true) {
      // @ts-ignore TS2339
      summary = <div className="synopsis">{this.item.cardInfo.synopsis}</div>;
    }
    let duration;
    // @ts-ignore TS2339
    if (this.props.showEpisodeDuration === true && this.item.cardInfo.episodeRuntime) {
      // @ts-ignore TS2339
      let runtime = parseInt(this.item.cardInfo.episodeRuntime, 10);
      let seconds = runtime % 60;
      let minutes = ((runtime - seconds) / 60) % 60;
      // @ts-ignore TS2345
      let hour = parseInt(runtime / 3600, 10);
      if (hour > 0) {
        if (minutes > 0) {
          duration = `${hour}時間${minutes}分`;
        } else {
          duration = `${hour}時間`;
        }
      } else if (minutes > 0) {
        duration = `${minutes}分`;
      } else {
        duration = `${seconds}秒`;
      }
      duration = <span className="duration">{duration}</span>;
    }

    let countdown;
    // @ts-ignore TS2339
    if (this.props.watchType === 'next-video') {
      countdown = (
        <div className="upNextin">
          <span className="starts">再生まであと</span>
          {/*
           // @ts-ignore TS2339 */}
          <span ref={this.countdownRef} className="countdown"></span>
          <span className="sec">秒</span>
        </div>
      );
    }
    let number;
    // @ts-ignore TS2339
    if (this.props.showEpisodeNumber === true) {
      number = (
        <div className="episodeNumber">
          {/*
           // @ts-ignore TS2339 */}
          <span aria-hidden="true">{this.item.episodeNumber}</span>
        </div>
      );
    }

    // 現状プログレスバー出すのはピックアップepだけなのでbookmarkPositionを参照
    const resumeKey = 'bookmarkPosition';
    let progress = 0;
    // @ts-ignore TS2339
    if (this.item.cardInfo.episodeRuntime && this.item[resumeKey]) {
      // @ts-ignore TS2339
      if (!!this.item.cardInfo.episodeRuntime && !!this.item[resumeKey]) {
        // @ts-ignore TS2345
        progress = Math.min(parseInt((this.item[resumeKey] / this.item.cardInfo.episodeRuntime) * 100), 100);
      }
    }

    // @ts-ignore TS2339
    const tags = activeTags(this.item, this.context);
    let cardBadge;
    // @ts-ignore TS2339
    if (this.state.hasPop && this.props.popType !== 'none') {
    } else if (!_.isEmpty(tags)) {
      // アートの上には一つのタグしか表示させない
      cardBadge = <div className="card-badge">{tags[0]}</div>;
    }

    const browserInfo = this.context.getModelData('browserInfo');
    const userInfo = this.context.getModelData('userInfo');

    let watchType, watchTypeText, watchSeasonTitle;
    let watchSeasonTitleShow = false;

    if (
      // @ts-ignore TS2339
      this.item.seasonMeta &&
      // @ts-ignore TS2339
      this.item.seriesMeta &&
      // @ts-ignore TS2339
      this.item.seasonMeta.shortName &&
      // @ts-ignore TS2339
      this.item.seriesMeta.shortName &&
      // @ts-ignore TS2339
      this.item.seasonMeta.shortName !== this.item.seriesMeta.shortName
    ) {
      // @ts-ignore TS2339
      watchSeasonTitle = this.item.seasonMeta.shortName;
    }
    // @ts-ignore TS2339
    if (this.props.noTitle) {
      watchType = '';
      // @ts-ignore TS2339
    } else if (this.props.watchType === 'first' || this.props.watchType === 'latest') {
      // @ts-ignore TS2339
      if (_.has(this.item, resumeKey) && (_.get(this.item, 'schemaId') != 4 || _.get(this.item, resumeKey, 0) != 0)) {
        // DVR可&&レジュームがある場合はは続きからにしたいので(DVR不可の場合はレジューム0)
        watchType = <p className="watch-type ellipsized">続きから再生</p>;
        watchTypeText = '続きから再生';
        watchSeasonTitleShow = true;
      } else {
        // @ts-ignore TS2339
        if (this.item.schemaId == 4) {
          watchType = <p className="watch-type ellipsized">再生する</p>;
          watchTypeText = '再生する';
          watchSeasonTitleShow = true;
        } else {
          // const str = this.props.watchType === 'latest' ? '最新' : '最初';
          watchType = <p className="watch-type ellipsized">最初から再生する</p>;
          watchTypeText = '最初から再生する';
          watchSeasonTitleShow = true;
        }
      }
      // @ts-ignore TS2339
    } else if (this.props.watchType === 'next-episode') {
      const title = _.get(this.props, 'nextEpisodeText') || '次のエピソード';
      watchType = <p className="watch-type ellipsized">{title}</p>;
      watchTypeText = `${title}を再生する`;
      // @ts-ignore TS2339
    } else if (this.props.watchType === 'next-video') {
      watchType = <p className="watch-type">次の動画</p>;
      watchTypeText = '次の動画を再生する';
    }

    // @ts-ignore TS2339
    let showEpisodeProgress = this.props.showEpisodeProgress;
    if (
      // @ts-ignore TS2339
      !_.has(this.item, resumeKey) ||
      userInfo.status === 'NON_REGISTERED_MEMBER' ||
      // @ts-ignore TS2339
      _.get(this.item, 'schemaId') == 4
    )
      showEpisodeProgress = false;

    let content = (
      // @ts-ignore TS2322
      <PresTrackedElement idDoubleWide={this.props.isDoubleWide}>
        <div className="artwork-container">
          <BackgroundImage
            // @ts-ignore TS2322
            onClick={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleClickPlayButton : null}
            className={classnames('artwork')}
            // @ts-ignore TS2339
            url={this.item.thumbnailUrl}
          >
            {cardBadge}
            {// 配信期間中のもののみ再生アイコンを表示する
            // @ts-ignore TS2339
            !getIsDelivery(this.item.cardInfo.deliveryStartAt, this.item.cardInfo.deliveryEndAt) ? null : (
              <div className="play-link"></div>
            )}
          </BackgroundImage>
          {/*
           // @ts-ignore TS2339 */}
          {showEpisodeProgress && !(this.props.btnVisible && (browserInfo.isIOS || browserInfo.isAndroid)) && (
            <div className="progress">
              <span className="progress-bar">
                <span role="presentation" className="progress-completed" style={{ width: `${progress}%` }}></span>
              </span>
            </div>
          )}
        </div>
        <div className="text-box">
          {/*
           // @ts-ignore TS2339 */}
          {this.props.btnVisible && (browserInfo.isIOS || browserInfo.isAndroid) ? null : watchType}
          <div className="title">
            {/*
             // @ts-ignore TS2339 */}
            {watchSeasonTitle && watchSeasonTitleShow && watchSeasonTitle !== this.item.shortName ? (
              <span className="season-title ellipsized">{watchSeasonTitle}</span>
            ) : null}
            {/*
             // @ts-ignore TS2339 */}
            <p>{this.item.shortName}</p>
          </div>
          {/*
           // @ts-ignore TS2339 */}
          {(this.props.showEpisodeNumber === true && this.item.cardInfo.episodeNumberTitle) || duration ? (
            <div className="top-metadata">
              {/*
               // @ts-ignore TS2339 */}
              {this.props.showEpisodeNumber === true && this.item.cardInfo.episodeNumberTitle ? (
                // @ts-ignore TS2339
                <span className="episode-number">{this.item.cardInfo.episodeNumberTitle}</span>
              ) : null}
              {duration}
            </div>
          ) : null}
          {/*
           // @ts-ignore TS2339 */}
          {this.props.btnVisible && (browserInfo.isIOS || browserInfo.isAndroid) ? (
            <React.Fragment>
              {showEpisodeProgress && (
                <div className="progress">
                  <span className="progress-bar">
                    <span role="presentation" className="progress-completed" style={{ width: `${progress}%` }}></span>
                  </span>
                </div>
              )}
              {watchType}
            </React.Fragment>
          ) : null}
          {countdown}
        </div>
      </PresTrackedElement>
    );

    // @ts-ignore TS2339
    const WapperLayout = this.props.wapperLayout
      ? // @ts-ignore TS2339
        this.props.wapperLayout
      : props => {
          return <React.Fragment>{props.children}</React.Fragment>;
        };

    if (browserInfo.isIOS || browserInfo.isAndroid) {
      const props = {
        to: routes.watchNow,
        // @ts-ignore TS2339
        params: { id: this.props.id },
      };
      // @ts-ignore TS2339
      if (this.item.refId) {
        props.to = routes.content;
        // @ts-ignore TS2339
        props.params = { id: this.item.refId };
      }
      return (
        <WapperLayout>
          <MainViewLink
            className={classnames('card', 'watch-card', 'mb', {
              // @ts-ignore TS2339
              clickable: this.props.enableCardClick,
              // @ts-ignore TS2339
              nohover: this.state.hoverClose,
            })}
            {...props}
            // @ts-ignore TS2322
            role="link"
            aria-label="card"
            tabIndex={0}
          >
            {content}
          </MainViewLink>
        </WapperLayout>
      );
    } else {
      return (
        <WapperLayout>
          {/*
           // @ts-ignore TS2339 */}
          {this.props.watchType === 'next-video' ? (
            <span
              className="watch-card__close"
              onClick={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleClickClose : null}
              onMouseEnter={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleMouseEnterClose : null}
              onMouseLeave={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleMouseLeaveClose : null}
            >
              <i className="fa fa-remove"></i> キャンセル
            </span>
          ) : null}
          <div
            className={classnames('card', 'watch-card', {
              // @ts-ignore TS2339
              clickable: this.props.enableCardClick,
              // @ts-ignore TS2339
              nohover: this.state.hoverClose,
            })}
            // @ts-ignore TS2339
            onClick={this.props.enableCardClick ? this.handleClickPlayButton : undefined}
            // @ts-ignore TS2339
            onKeyDown={this.props.enableCardClick ? this.handleKeyDown.bind(this) : undefined}
            tabIndex={0}
          >
            {content}
          </div>
        </WapperLayout>
      );
    }
  }

  fetchData(props) {
    if (props.id == null) {
      // @ts-ignore TS2339
      this.item = null;
      return;
    }
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, null, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.omit(_.get(res.json, ['meta', props.id]), ['$__path']);
        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
        };
        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState(newState);
        } else {
          Object.assign(this.state, newState);
        }
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }
}
