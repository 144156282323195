import React, { Component } from 'react';
import _ from 'src/libs/util';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';

import GalleryContent from './GalleryContent';
import EventCard from './Epg/EventCard';
import GalleryTab from './GalleryTab';
import Canvas from './Canvas';
import AttributeHeader from './AttributeHeader';
import LoadingGallery from '../loader/LoadingGallery';
import HtmlContext from '../../../common/context/HtmlContext';
import { NotfoundError } from '../../../../constants/error';
import MainViewLink from '../../../common/components/MainViewLink';
import JsonLd from '../../../common/components/JsonLd';
import routes from '../../../common/routes';
import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import PaletteContent from './PaletteContent';

export default class Attribute extends Component {
  static searchOptions = function(props) {
    if (props.tSearch > 1 || props.sSearch > 1) {
      const searchFilters = [];
      // 未設定:1 対象:2 除外:3
      if (props.tSearch > 1) searchFilters.push(`t:${props.tSearch}`);
      if (props.sSearch > 1) searchFilters.push(`s:${props.sSearch}`);
      props.searchFilter = _.join(searchFilters, '|');
    }
    return {
      filterType: _.get(props, 'filterType', _.get(props.query, 'ft')) || 'a',
      sortOrder: _.get(props, 'sortOrder', _.get(props.query, 'so')) || 'ps',
      searchFilter: _.get(props, 'searchFilter', _.get(props.query, 'sf')) || 'p:a',
    };
  };

  static getPathProps() {
    return ['id', 'refId', 'name', 'type', 'canvas', 'palette'];
  }

  static getPaths = function(models, options, props) {
    let paths = [];
    const rootPath = this.getRootPath(models, options, props);
    paths = paths.concat([rootPath.concat([Attribute.getPathProps()])]);

    const genreHeaderPaths = AttributeHeader.getPaths(models, options, {
      id: props.id,
      breadCrumbs: props.breadCrumbs,
      pqls: [['name', 'type']],
      filterType: props.filterType || 'a',
      sortOrder: props.sortOrder || 'po',
      type: props.type,
    });

    if (props.showCanvas) {
      paths = paths.concat(Canvas.getPaths(models, options, props));
    } else if (props.showPalette) {
      const pathProps = {
        ...props,
        id: props.paletteId,
        palette: {
          id: props.paletteId,
        },
      };
      paths = paths.concat(PaletteContent.getPaths(models, options, pathProps));
    } else {
      let tab = _.find(props.tabInfo, info => {
        return _.get(info.query, 'type') == _.get(props, 'routeHandler.query.type');
      });
      if (!tab) tab = _.first(props.tabInfo);
      const galleryRootPath = GalleryContent.getRootPath(
        models,
        options,
        Object.assign({}, props, Attribute.searchOptions(props), { keyPrefix: tab.key }),
      );
      const galleryPaths = GalleryContent.getPaths(models, options, Object.assign({}, this.defaultProps)).map(function(
        path,
      ) {
        return galleryRootPath.concat(path);
      });
      paths = paths.concat(galleryPaths);

      _.map(props.tabInfo, tab => {
        paths = paths.concat([
          GalleryContent.getRootPath(
            models,
            options,
            Object.assign({}, props, Attribute.searchOptions(props), { keyPrefix: tab.key }),
          ).concat(['length']),
        ]);
      });
    }

    paths = paths.concat(genreHeaderPaths);
    return paths;
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (props.id) {
      // @ts-ignore TS2339
      return [props.type, props.id];
    }
    return [];
  };

  static getPrefetchPaths = function(models, options, props) {
    let paths = [];
    const rootPath = Attribute.getRootPath(models, options, props);
    paths = paths.concat([rootPath.concat([Attribute.getPathProps()])]);
    return paths;
  };

  static getPrefetchedPaths = function(models, options, props) {
    return data => {
      const self = Attribute;
      const rootPath = self.getRootPath(models, options, props);
      const attr = _.get(data.json, rootPath) || {};
      const { showCanvas, showPalette } = Attribute.getShowCanvasOrPalette({
        isGrid: props.isGrid,
        routeHandler: props.routeHandler,
        attribute: attr,
      });
      const pathProps = { ...props };
      if (showCanvas) {
        pathProps.showCanvas = true;
        pathProps.canvasId = attr.canvas;
      } else if (showPalette) {
        pathProps.showPalette = true;
        pathProps.paletteId = attr.palette;
      }
      Object.assign(pathProps, Attribute.searchOptions(props.routeHandler));
      return self.getPaths(models, options, pathProps);
    };
  };

  static afterPrefetch = function(models, options, props) {
    return data => {
      const item = _.omit(_.get(data.json, this.getRootPath(models, options, props)), ['$__path']);
      if (!item.id) {
        // @ts-ignore TS2554
        return { error: new NotfoundError() };
      }
    };
  };

  static getShowCanvasOrPalette(props) {
    let _state = {
      showCanvas: false,
      showPalette: false,
    };
    const sortOrder = props.routeHandler.query['so'];
    const tabType = props.routeHandler.query['type'];
    if (!sortOrder && !tabType && !props.isGrid) {
      if (!!_.get(props.attribute, 'canvas')) {
        _state.showCanvas = true;
      } else if (!!_.get(props.attribute, 'palette')) {
        _state.showPalette = true;
      }
    }
    return _state;
  }

  static get propTypes() {
    return {
      history: PropTypes.object,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      isPrefetchingPaths: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      perPage: 60,
    };
  }

  static get contextTypes() {
    return {
      history: PropTypes.object,
      routeHandler: PropTypes.object,
      getModelData: PropTypes.func,
      gtmApp: PropTypes.object,
      models: PropTypes.object,
    };
  }

  static get childContextTypes() {
    return {
      hideGenreIds: PropTypes.array,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleFetchedCount = this.handleFetchedCount.bind(this);
    this.handleNoContents = this.handleNoContents.bind(this);
    this.checkOrientation = this.checkOrientation.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);

    // @ts-ignore TS2339
    this.model = props.model || (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.model, {}, props);
    // @ts-ignore TS2339
    this.attribute = this.model.getSync(rootPath) || {};
    // @ts-ignore TS2339
    if (this.attribute.canvas) {
      // @ts-ignore TS2339
      const canvasRootPath = ['canvas', this.attribute.canvas];
      // @ts-ignore TS2339
      this.canvas = this.model.getSync(canvasRootPath) || {};
    }
    // @ts-ignore TS2339
    if (this.attribute.palette) {
      // @ts-ignore TS2339
      const paletteRootPath = ['palette', this.attribute.palette];
      // @ts-ignore TS2339
      this.palette = this.model.getSync(paletteRootPath) || {};
    }

    const searchOptions = Attribute.searchOptions(props.routeHandler);
    // @ts-ignore TS2339
    this.tabs = _.cloneDeep(props.tabInfo);
    // @ts-ignore TS2339
    _.map(this.tabs, tab => {
      // 件数をバインド
      // @ts-ignore TS2339
      tab.length = this.model.getSync(
        GalleryContent.getRootPath(
          // @ts-ignore TS2339
          this.model,
          {},
          Object.assign({}, props, searchOptions, { keyPrefix: tab.key }),
        ).concat(['length']),
        0,
      );
      // filter
      if (searchOptions.searchFilter != 'p:a') tab.query.sf = searchOptions.searchFilter;
    });
    const { showCanvas, showPalette } = Attribute.getShowCanvasOrPalette({
      isGrid: props.isGrid,
      routeHandler: context.routeHandler,
      // @ts-ignore TS2339
      attribute: this.attribute,
    });
    this.state = {
      filter: null,
      showSortContent: false,
      showCanvas,
      showPalette,
      isPortrait: false,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props, this.context);
    this.sendToGtm();
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkOrientation);
    }
    this.checkOrientation();
  }

  componentDidUpdate(prevProps, prevState) {
    // @ts-ignore TS2339
    if (this.state.showCanvas !== prevState.showCanvas || this.state.showPalette !== prevState.showPalette) {
      this.fetchData(this.props, this.context);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkOrientation);
    }
  }

  getChildContext() {
    return {
      // @ts-ignore TS2339
      hideGenreIds: this.props.id ? [this.props.id] : [],
    };
  }

  handleChangeQuery(query) {
    // @ts-ignore TS2339
    if (this._isMounted) this.setState({ query: query });
  }

  handleFetchedCount(count) {
    this.setState({ showSortContent: count > 0 });
  }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-ignore TS2339
    this.context.gtmApp.pageView(_.get(this.attribute, 'name'));
  }

  handleNoContents() {
    this.setState({ showCanvas: false });
  }

  fetchData(props, context) {
    const pathProps = { ...props };
    // @ts-ignore TS2339
    if (this.attribute) {
      // @ts-ignore TS2339
      pathProps.canvasId = this.attribute.canvas;
      // @ts-ignore TS2339
      pathProps.paletteId = this.attribute.palette;
      // @ts-ignore TS2339
      pathProps.showCanvas = this.state.showCanvas;
      // @ts-ignore TS2339
      pathProps.showPalette = this.state.showPalette;
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, pathProps);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(context.models, {}, pathProps);
    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.attribute = _.get(res.json, rootPath, {});
        // @ts-ignore TS2339
        if (this.attribute.canvas) {
          // @ts-ignore TS2339
          this.canvas = _.get(res.json, ['canvas', this.attribute.canvas], {});
        }
        // @ts-ignore TS2339
        if (this.attribute.palette) {
          // @ts-ignore TS2339
          this.palette = _.get(res.json, ['palette', this.attribute.palette], {});
        }
        const { showCanvas, showPalette } = Attribute.getShowCanvasOrPalette({
          isGrid: props.isGrid,
          routeHandler: context.routeHandler,
          // @ts-ignore TS2339
          attribute: this.attribute,
        });
        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.model.getVersion(rootPath),
          showCanvas,
          showPalette,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  checkOrientation() {
    if (typeof window === 'undefined') return;

    const browserInfo = this.context.getModelData('browserInfo');

    if (!(browserInfo.isIOS || browserInfo.isAndroid)) {
      this.setState({ isPortrait: true });
    } else {
      let angle = screen && screen.orientation && screen.orientation.angle;
      let isPortrait = false;

      if (angle === undefined) {
        angle = window.orientation;

        if (angle === undefined) {
          if (window.innerHeight > window.innerWidth) {
            angle = 0;
          } else {
            angle = 90;
          }
        }
      }
      isPortrait = angle === 0;

      // @ts-ignore TS2339
      if (this._isMounted) {
        this.setState({ isPortrait });
      } else {
        // @ts-ignore TS2339
        this.state.isPortrait = isPortrait;
      }
    }
  }

  render() {
    // @ts-ignore TS2339
    if (!this.props.id) return null;
    // @ts-ignore TS2339
    if (!this.attribute || Object.keys(this.attribute).length === 0) return null;

    let content;
    const { routeHandler } = this.context;
    // @ts-ignore TS2339
    if (this.state.showCanvas) {
      content = (
        <Canvas
          {...this.props}
          noContents={this.handleNoContents}
          // @ts-ignore TS2339
          model={this.model}
          // @ts-ignore TS2339
          canvasId={this.attribute.canvas}
        />
      );
      // @ts-ignore TS2339
    } else if (this.state.showPalette) {
      // @ts-ignore TS2339
      if (this.palette.listContext === 'editorial' || this.palette.listContext === 'recommended') {
        content = (
          <PaletteContent
            {...this.props}
            // @ts-ignore TS2339
            id={this.attribute.palette}
            useHelmet={false}
            shouldSkipGtm={true}
            showSpaOnly={true}
          />
        );
      }
    } else {
      // @ts-ignore TS2339
      if (this.state.dispose) {
        content = (
          <div className="gallery row-with-x-columns">
            <div className="gallery-header clearfix">
              <div className="title">
                <span className="gallery-title"></span>
              </div>
            </div>
            <div className="gallery-content">
              {/*
               // @ts-ignore TS2339 */}
              <LoadingGallery pulsateTitles={true} isPortrait={this.state.isPortrait} />
            </div>
          </div>
        );
      } else {
        let tab;
        // type指定している場合
        if (_.get(routeHandler, 'query.type')) {
          // @ts-ignore TS2339
          tab = _.find(this.tabs, tab => {
            return _.get(tab.query, 'type') == _.get(routeHandler, 'query.type');
          });
          // してない場合は件数があるのを優先
        } else {
          // @ts-ignore TS2339
          tab = _.find(this.tabs, tab => {
            return tab.length > 0;
          });
        }
        // なければデフォルト
        // @ts-ignore TS2339
        if (!tab) tab = _.first(this.tabs);
        // @ts-ignore TS2339
        const activeTabIndex = _.findIndex(this.tabs, tab);
        // @ts-ignore TS2339
        const links = _.map(this.tabs, (tab, index) => {
          return (
            <MainViewLink
              key={`tab_${tab.key}`}
              // @ts-ignore TS2322
              to={routeHandler.route}
              params={routeHandler.params}
              query={tab.query}
              // @ts-ignore TS2367
              className={classnames({ active: tab.length > 0 || activeTabIndex == index })}
            >
              {tab.name} ({tab.length})
            </MainViewLink>
          );
        });
        const galleryProps = [
          {
            // @ts-ignore TS2339
            keyPrefix: this.tabs[0].key,
            listType: false,
          },
          {
            // @ts-ignore TS2339
            keyPrefix: this.tabs[1].key,
            cardComponent: EventCard,
            showPopCardMylistButton: false,
            listType: true,
            itemsInRow: 1,
            showSynopsis: true,
          },
        ];

        const header = (
          <AttributeHeader
            // @ts-ignore TS2339
            id={this.props.id}
            pqls={[['name', 'type']]}
            {...Attribute.searchOptions(routeHandler)}
            // @ts-ignore TS2322
            showSortContent={tab.sort ? this.state.showSortContent : false}
            onChangeQuery={this.handleChangeQuery}
            // @ts-ignore TS2339
            model={this.model}
            // @ts-ignore TS2339
            type={this.props.type}
            // @ts-ignore TS2322
            galleryTab={<GalleryTab links={links} activeTabIndex={activeTabIndex} />}
          />
        );

        content = (
          <GalleryContent
            // @ts-ignore TS2339
            id={this.props.id}
            header={header}
            // @ts-ignore TS2322
            onFetchedCount={this.handleFetchedCount}
            // @ts-ignore TS2339
            keyPrefix={this.props.type}
            // @ts-ignore TS2339
            perPage={this.props.perPage}
            {...Attribute.searchOptions(routeHandler)}
            // @ts-ignore TS2339
            model={this.model}
            {...galleryProps[activeTabIndex]}
          />
        );
      }
    }

    const jsonLdProps = {};
    const links = [];
    const host = this.context.getModelData('hosts', 'host');
    // @ts-ignore TS2339
    if (this.attribute.id) {
      let route = routes.attr;
      // @ts-ignore TS2339
      const params = { id: this.attribute.id };
      const query = {};
      // @ts-ignore TS2339
      if (_.includes(['genre', 'person', 'team', 'league'], this.attribute.type)) {
        // @ts-ignore TS2339
        route = routes[this.attribute.type];
        // @ts-ignore TS2339
        if (this.attribute.type === 'genre') {
          const sortOrder = this.context.routeHandler.query['so'];
          const tabType = this.context.routeHandler.query['type'];
          if (sortOrder || tabType) {
            // @ts-ignore TS2339
            if (sortOrder) query.so = sortOrder;
            // @ts-ignore TS2339
            if (tabType) query.type = tabType;
            // @ts-ignore TS2339
          } else if (this.attribute.refId) {
            route = routes.genreRef;
            // @ts-ignore TS2339
            params.id = this.attribute.refId;
          }
        }
      }
      // @ts-ignore TS2554
      links.push({ rel: 'canonical', href: host + route.makePath(params, query) });

      // @ts-ignore TS2339
      if (this.attribute.name) {
        const url = this.context.routeHandler.path;
        // @ts-ignore TS2339
        jsonLdProps.breadcrumbList = {
          // @ts-ignore TS2339
          itemListElement: [{ name: this.attribute.name, item: host + url }],
        };
      }
    }

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ title, keywords }) => {
            const metas = [];

            // @ts-ignore TS2339
            if (this.attribute.name) {
              // @ts-ignore TS2339
              metas.push({ property: 'og:title', content: title(this.attribute.name) });
              // @ts-ignore TS2339
              metas.push({ name: 'keywords', content: keywords(this.attribute.name) });
              // @ts-ignore TS2339
              if (this.props.metaDescription) {
                // @ts-ignore TS2339
                metas.push({ name: 'description', content: this.props.metaDescription });
                // @ts-ignore TS2339
                metas.push({ property: 'og:description', content: this.props.metaDescription });
              }
            }

            // @ts-ignore TS2339
            return <Helmet title={this.attribute.name} meta={metas} link={links} />;
          }}
        </HtmlContext.Consumer>
        <JsonLd {...jsonLdProps} />
        {content}
      </React.Fragment>
    );
  }
}
